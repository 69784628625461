
import { AppEvents } from "@/control/app-events";
import { Request } from "@/utils/request";
import { defineComponent } from "vue";
import { useVModel } from "../../../utils/vmodel";
import { FocusTrap } from "../../../utils/focus-trap";
import { WalletAPI } from "@/api/api-wallet";
import { WalletsController } from "@/control/wallets";

export default defineComponent({
  name: "WalletDeleteModal",
  emits: ["update:display", "wallet-delete"],
  props: {
    display: Boolean,
  },
  setup(props) {
    return {
      displayStatus: useVModel(props, "display"),
    };
  },
  data: function () {
    return {
      walletPassword: "",
      showPassword: false,

      wid: WalletsController.CurrentWalletId,

      busy: false,
      error: "",
    };
  },
  methods: {
    autoFocus: function () {
      const elem = this.$el.querySelector(".auto-focus");
      if (elem) {
        setTimeout(() => {
          elem.focus();
        }, 200);
      }
    },

    onOpen: function () {
      this.walletPassword = "";
      this.error = "";
      this.wid = WalletsController.CurrentWalletId;
      this.autoFocus();
    },

    close: function () {
      this.displayStatus = false;
    },

    open: function () {
      this.displayStatus = true;
    },

    escapeToClose: function (event) {
      if (event.key === "Escape") {
        this.close();
      }
    },

    stopPropagationEvent: function (e) {
      e.stopPropagation();
    },

    submit: function (e) {
      if (e) {
        e.preventDefault();
      }
      if (this.busy) {
        return;
      }

      this.busy = true;
      this.error = "";
      Request.Do(WalletAPI.DeleteWallet({ id: this.wid, password: this.walletPassword }))
        .onSuccess(() => {
          this.busy = false;
          this.close();
          AppEvents.Emit("snack", this.$t("Wallet deleted successfully"));
          this.$emit("wallet-delete");
          WalletsController.Load();
        })
        .onCancel(() => {
          this.busy = false;
        })
        .onRequestError((err) => {
          this.busy = false;
          Request.ErrorHandler()
            .add(401, "*", () => {
              AppEvents.Emit("unauthorized");
            })
            .add(400, "*", () => {
              this.error = this.$t("Bad request");
            })
            .add(404, "*", () => {
              this.error = this.$t("Wallet not found");
            })
            .add(403, "WALLET_NOT_UNLOCKED", () => {
              this.error = this.$t("Wrong wallet password provided");
            })
            .add(403, "*", () => {
              this.error = this.$t("Access denied");
            })
            .add(500, "*", () => {
              this.error = this.$t("Internal server error");
            })
            .add("*", "*", () => {
              this.error = this.$t("Could not connect to the server");
            })
            .handle(err);
        })
        .onUnexpectedError((err) => {
          this.error = err.message;
          console.error(err);
          this.busy = false;
        });
    },
  },
  mounted: function () {
    this.$options.openH = this.open.bind(this);
    AppEvents.AddEventListener("delete-wallet-open", this.$options.openH);

    this.$options.focusTrap = new FocusTrap(this.$el, this.close.bind(this));
  },
  beforeUnmount: function () {
    AppEvents.RemoveEventListener("delete-wallet-open", this.$options.openH);
    if (this.$options.focusTrap) {
      this.$options.focusTrap.destroy();
    }
  },
  watch: {
    display: function () {
      if (this.display) {
        this.$options.focusTrap.activate();
        this.onOpen();
      } else {
        this.$options.focusTrap.deactivate();
      }
    },
  },
});
