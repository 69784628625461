
import { AppEvents } from "@/control/app-events";
import { defineComponent, nextTick } from "vue";
import { useVModel } from "../../utils/vmodel";
import { FocusTrap } from "../../utils/focus-trap";
import { WalletsController } from "@/control/wallets";
import { Request } from "@/utils/request";
import { BalanceResponse, CurrencyTokenAPI } from "@/api/api-currency-token";
import { Timeouts } from "@/utils/timeout";
import { renderBigIntegerQuantity } from "@/utils/bigint";
import { StripeAccountAPI } from "@/api/api-stripe-account";
import { AuthController } from "@/control/auth";
import { copyAddressUtil } from '@/utils/copy-address';

const LOAD_BALANCE_REQUEST_ID = "balance-load";

export default defineComponent({
  name: "WalletSettingsModal",
  emits: ["update:display"],
  props: {
    display: Boolean,
  },
  setup(props) {
    return {
      displayStatus: useVModel(props, "display"),
    };
  },
  data: function () {
    const org = AuthController.FindOrganization(WalletsController.CurrentWalletOrganization);
    return {
      id: WalletsController.CurrentWalletId,
      name: WalletsController.CurrentWalletName,
      address: WalletsController.CurrentWalletAddress,
      wallets: WalletsController.GetWallets(),
      organization: org ? org.name : "",
      canAdminWallet: !org || (org.admin || org.owner),
      loading: false,
      notFound: false,
      balance: "",
      symbol: "",
      decimals: 0,
    };
  },
  methods: {

    loadBalance: function () {
      this.loading = true;
      this.address = WalletsController.CurrentWalletAddress;
      Request.Pending(LOAD_BALANCE_REQUEST_ID, CurrencyTokenAPI.GetBalance(this.address)
      )
        .onSuccess((balance: BalanceResponse) => {
          this.balance = balance.balance;
          this.symbol = balance.symbol;
          this.decimals = balance.decimals;
          this.loading = false;
        })
        .onRequestError((err) => {
          this.busy = false;
          Request.ErrorHandler()
            .add(401, "*", () => {
              AppEvents.Emit("unauthorized");
            })
            .add(404, "*", () => {
              this.notFound = true;
              this.loading = false;
            })
            .add(500, "*", () => {
              this.error = this.$t("Internal server error");
            })
            .add("*", "*", () => {
              Timeouts.Set(LOAD_BALANCE_REQUEST_ID, 2000, this.load.bind(this));
            })
            .handle(err);
        })
        .onUnexpectedError((err) => {
          console.error(err);
          Timeouts.Set(LOAD_BALANCE_REQUEST_ID, 2000, this.load.bind(this));
        });
    },

    open: function () {
      this.displayStatus = true;
      WalletsController.Load();
    },

    close: function () {
      this.displayStatus = false;
    },

    escapeToClose: function (event) {
      if (event.key === "Escape") {
        this.close();
      }
    },

    stopPropagationEvent: function (e) {
      e.stopPropagation();
    },

    clickOnEnter: function (event) {
      if (event.key === "Enter") {
        event.preventDefault();
        event.stopPropagation();
        event.target.click();
      }
    },

    onCurrentWalletChanged: function () {
      this.id = WalletsController.CurrentWalletId;
      this.name = WalletsController.CurrentWalletName;
      this.address = WalletsController.CurrentWalletAddress;

      const org = AuthController.FindOrganization(WalletsController.CurrentWalletOrganization);

      this.organization = org ? org.name : "";
      this.canAdminWallet = !org || (org.admin || org.owner);
    },

    onWalletListChanged: function () {
      this.wallets = WalletsController.GetWallets();
    },

    renderWalletAddress: function (a: string) {
      return a.substring(0, 8) + "..." + a.substring(a.length - 8);
    },

    renderValue: function (balance: string, decimals: number) {
      return renderBigIntegerQuantity(balance, decimals);
    },

    openUserSettings: function () {
      AppEvents.Emit("account-settings-open");
    },

    openTransferWallet: function () {
      AppEvents.Emit("transfer-tokens-wallet-open");
    },

    copyAddressUtil,

    openSelectWallet: function () {
      this.close();
      AppEvents.Emit("select-wallet-open");
    },

    openCreateWallet: function () {
      AppEvents.Emit("create-wallet-open");
    },

    openAddFundsWallet: function () {
      AppEvents.Emit("add-funds-wallet-open");
    },

    createAccount: function () {
      Request.Do(StripeAccountAPI.CreateStripeAccount())
        .onSuccess((url) => {
          window.location.replace(url.url);
        })
        .onRequestError(() => {
          this.close();
        })
        .onUnexpectedError(() => {
          this.close();
        });
    },

    updateAccount: function () {
      Request.Do(StripeAccountAPI.UpdateStripeAccount())
        .onSuccess((url) => {
          window.location.replace(url.url);
        })
        .onRequestError(() => {
          this.close();
        })
        .onUnexpectedError(() => {
          this.close();
        });
    },

    openStripeAccount: function () {
      Request.Pending("wallet-stripe-account-check", StripeAccountAPI.RetrieveStripeAccount())
        .onSuccess(() => {
          this.updateAccount();
        })
        .onRequestError(err => {
          Request.ErrorHandler()
            .add(401, "*", () => {
              AppEvents.Emit("unauthorized");
            })
            .add(404, "*", () => {
              this.createAccount();
            })
            .add("*", "*", () => {
              Timeouts.Set("wallet-stripe-account-check", 2000, this.openStripeAccount.bind(this));
            })
            .handle(err);
        })
        .onUnexpectedError(err => {
          console.error(err);
          Timeouts.Set("wallet-stripe-account-check", 2000, this.openStripeAccount.bind(this));
        });
    },

    openWalletDelete: function () {
      AppEvents.Emit("delete-wallet-open");
    },

    openExportKey: function () {
      AppEvents.Emit("export-wallet-open");
    },

    openRenameWallet: function () {
      AppEvents.Emit("rename-wallet-open");
    },

    openChangePassword: function () {
      AppEvents.Emit("password-wallet-open");
    },
  },
  mounted: function () {
    this.$options.openH = this.open.bind(this);
    AppEvents.AddEventListener("wallet-settings-open", this.$options.openH);

    this.$options.onCurrentWalletChangedH = this.onCurrentWalletChanged.bind(this);
    AppEvents.AddEventListener("current-wallet-changed", this.$options.onCurrentWalletChangedH);

    this.$options.onWalletListChangedH = this.onWalletListChanged.bind(this);
    AppEvents.AddEventListener("wallet-list-changed", this.$options.onWalletListChangedH);

    this.$options.focusTrap = new FocusTrap(this.$el, this.close.bind(this));
  },
  beforeUnmount: function () {
    AppEvents.RemoveEventListener("wallet-settings-open", this.$options.openH);
    AppEvents.RemoveEventListener("current-wallet-changed", this.$options.onCurrentWalletChangedH);
    AppEvents.RemoveEventListener("wallet-list-changed", this.$options.onWalletListChangedH);
    this.$options.focusTrap.destroy();
  },
  watch: {
    display: function () {
      if (this.display) {
        this.loadBalance();
        this.$options.focusTrap.activate();
        nextTick(() => {
          this.$el.focus();
        });
      } else {
        this.$options.focusTrap.deactivate();
      }
    },
  },
});
