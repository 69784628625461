// App preferences

import { AppEvents } from "./app-events";
import { LocalStorage } from "./local-storage";

export class AppPreferences {
  public static Language = "en";
  public static Theme = "light";

  public static AvailableLanguages = [
    {
      id: 'en',
      name: 'English'
    },
    {
      id: 'es',
      name: 'Español (Internacional)'
    },
  ];

  public static LoadPreferences() {
    const locale = (navigator.language.split("-")[0] || "en");

    AppPreferences.Language = LocalStorage.Get("app-pref-lang", locale);
    AppPreferences.Theme = LocalStorage.Get("app-pref-theme", "light");
  }

  public static SetLanguage(lang: string) {
    AppPreferences.Language = lang;
    LocalStorage.Set("app-pref-lang", lang);
    AppEvents.Emit("language-changed", lang);
  }

  public static SetTheme(t: string) {
    AppPreferences.Theme = t;
    LocalStorage.Set("app-pref-theme", t);
    AppEvents.Emit("theme-changed", t);
  }

  public static GetDefaultWallet(uid: string): string {
    return LocalStorage.Get("app-pref-default-wallet-" + uid, "");
  }

  public static SetDefaultWallet(uid: string, wallet: string) {
    LocalStorage.Set("app-pref-default-wallet-" + uid, wallet);
  }

  public static GetSelectedOrganization(uid: string): string {
    return LocalStorage.Get("app-pref-selected-org-" + uid, "");
  }

  public static SetSelectedOrganization(uid: string, org: string) {
    LocalStorage.Set("app-pref-selected-org-" + uid, org);
  }
}