// Big integer utils

"use strict";

import Decimal from "decimal.js";

export function parseBigInteger(bi: string): bigint {
    try {
        return BigInt(bi);
    } catch (ex) {
        return BigInt(0);
    }
}

export function renderBigIntegerQuantity(q: string, decimals: number): string {
    const bi = parseBigInteger(q);

    if (decimals <= 0 || decimals > 128) {
        return bi.toString(10);
    }

    let decimal = new Decimal(bi.toString(10));

    decimal = decimal.div((new Decimal(10)).pow(new Decimal(decimals)));

    return decimal.toString();
}

export function getBigIntegerQuantity(q: string | number, decimals: number): bigint {
    if (typeof q === "number") {
        q = Math.floor(q) + "";
    }

    if (decimals <= 0 || decimals > 128) {
        return parseBigInteger(q);
    }

    try {
        const decimal = new Decimal(q);
        const decimalMod = decimal.mul((new Decimal(10)).pow(new Decimal(decimals)));
        const decimalFloor = decimalMod.floor();

        if (!decimalMod.equals(decimalFloor)) {
            throw new Error("Dropped decimals");
        }

        return BigInt(decimalFloor.toHex());
    } catch (ex) {
        console.error(ex);
        return BigInt(0);
    }
}
