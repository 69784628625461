
import { AppEvents } from "@/control/app-events";
import { Request } from "@/utils/request";
import { defineComponent } from "vue";
import { useVModel } from "../../../utils/vmodel";
import { FocusTrap } from "../../../utils/focus-trap";
import { WalletsController } from "@/control/wallets";
import { PaymentAPI } from "@/api/api-payment";
import { AuthController } from "@/control/auth";
import { getBigIntegerQuantity, renderBigIntegerQuantity } from "@/utils/bigint";
import { BalanceResponse, CurrencyTokenAPI } from "@/api/api-currency-token";

import ProgressModal from "@/components/modals/ProgressModal.vue";

export default defineComponent({
  components: {
    ProgressModal,
  },
  name: "WalletFundsWithdrawModal",
  emits: ["update:display"],
  props: {
    display: Boolean,
  },
  setup(props) {
    return {
      displayStatus: useVModel(props, "display"),
    };
  },
  data: function () {
    return {
      name: "",
      balance: "",

      privKey: "",

      walletAddress: WalletsController.CurrentWalletAddress,
      walletName: WalletsController.CurrentWalletName,
      addressID: "",
      amount: "",
      walletPassword: "",
      showPassword: false,

      tokenCurrency: AuthController.TokenSymbol,
      decimals: AuthController.TokenDecimals,

      busy: false,
      error: "",
    };
  },
  methods: {
    autoFocus: function () {
      const elem = this.$el.querySelector(".auto-focus");
      if (elem) {
        setTimeout(() => {
          elem.focus();
        }, 200);
      }
    },

    open: function () {
      this.displayStatus = true;
    },

    onOpen: function () {
      this.walletAddress = WalletsController.CurrentWalletAddress;
      this.walletName = WalletsController.CurrentWalletName;
      this.addressID = WalletsController.CurrentWalletId;
      this.amount = "0";
      this.walletPassword = "";
      this.tokenCurrency = AuthController.TokenSymbol;
      this.decimals = AuthController.TokenDecimals;
      this.autoFocus();
    },

    loadBalance: function () {
      Request.Pending("load-balance-withdraw", CurrencyTokenAPI.GetBalance(this.walletAddress))
        .onSuccess((balance: BalanceResponse) => {
          this.balance = balance.balance;
        })
        .onCancel(() => {
          this.busy = false;
        })
        .onRequestError((err) => {
          this.busy = false;
          Request.ErrorHandler()
            .add(401, "*", () => {
              AppEvents.Emit("unauthorized");
            })
            .add(400, "*", () => {
              this.error = this.$t("Bad request");
            })
            .add(400, "WRONG_PASSWORD", () => {
              this.error = this.$t("Incorrect password");
            })
            .add(404, "*", () => {
              this.error = this.$t("Wallet not found");
            })
            .add(500, "*", () => {
              this.error = this.$t("Internal server error");
            })
            .add("*", "*", () => {
              this.error = this.$t("Could not connect to the server");
            })
            .handle(err);
        })
        .onUnexpectedError((err) => {
          this.error = err.message;
          console.error(err);
          this.busy = false;
        });
    },

    close: function () {
      this.displayStatus = false;
    },

    escapeToClose: function (event) {
      if (event.key === "Escape") {
        this.close();
      }
    },

    stopPropagationEvent: function (e) {
      e.stopPropagation();
    },

    renderWalletAddress: function (a: string) {
      return a.substring(0, 8) + "..." + a.substring(a.length - 8);
    },

    submit: function (e) {
      if (e) {
        e.preventDefault();
      }
      if (this.busy) {
        return;
      }

      if (!this.amount) {
        this.error = this.$t("You must specify an amount");
        return;
      }

      if (!this.walletPassword) {
        this.error = this.$t("You must specify a password");
        return;
      }

      const amountBigInt = getBigIntegerQuantity(this.amount, AuthController.TokenDecimals);

      this.busy = true;
      this.error = "";

      Request.Do(PaymentAPI.WithdrawBalance({ amount: amountBigInt.toString(10) }, { id: WalletsController.CurrentWalletId, password: this.walletPassword }))
        .onSuccess(() => {
          this.busy = false;  
          AppEvents.Emit("snack", this.$t("Balance withdrawn successfully"));
          this.close();
        })
        .onCancel(() => {
          this.busy = false;
        })
        .onRequestError((err) => {
          this.busy = false;
          Request.ErrorHandler()
            .add(401, "*", () => {
              AppEvents.Emit("unauthorized");
            })
            .add(400, "INVALID_AMOUNT", () => {
              this.error = this.$t("Invalid amount");
            })
            .add(400, "INSUFFICIENT_BALANCE", () => {
              this.error = this.$t("Insufficient balance");
            })
            .add(400, "NO_STRIPE_ACCOUNT", () => {
              this.error = this.$t("No stripe account connected");
            })
            .add(400, "*", () => {
              this.error = this.$t("Bad request");
            })
            .add(403, "WALLET_NOT_UNLOCKED", () => {
              this.error = this.$t("Incorrect password");
            })
            .add(404, "*", () => {
              this.error = this.$t("Wallet not found");
            })
            .add(500, "*", () => {
              this.error = this.$t("Internal server error");
            })
            .add("*", "*", () => {
              this.error = this.$t("Could not connect to the server");
            })
            .handle(err);
        })
        .onUnexpectedError((err) => {
          this.error = err.message;
          console.error(err);
          this.busy = false;
        });
    },

    onAuthChanged: function () {
      this.canCertificationBodies = AuthController.CanRegisterCertificationBodies();
      this.tokenCurrency = AuthController.TokenSymbol;
      this.decimals = AuthController.TokenDecimals;
    },

    onWalletChanged: function () {
      this.walletAddress = WalletsController.CurrentWalletAddress;
    },

    renderValue: function (balance: string, decimals: number) {
      return renderBigIntegerQuantity(balance, decimals);
    },
  },
  mounted: function () {
    this.$options.openH = this.open.bind(this);
    AppEvents.AddEventListener("withdraw-funds-wallet-open", this.$options.openH);

    this.$options.onAuthChangedH = this.onAuthChanged.bind(this);
    AppEvents.AddEventListener("auth-status-changed", this.$options.onAuthChangedH);

    this.$options.onWalletChangedH = this.onWalletChanged.bind(this);
    AppEvents.AddEventListener("current-wallet-changed", this.$options.onWalletChangedH);

    this.$options.focusTrap = new FocusTrap(this.$el, this.autoFocus.bind(this));

    this.walletAddress = WalletsController.CurrentWalletAddress;
  },
  beforeUnmount: function () {
    AppEvents.RemoveEventListener("withdraw-funds-wallet-open", this.$options.openH);
    AppEvents.RemoveEventListener("auth-status-changed", this.$options.onAuthChangedH);
    AppEvents.RemoveEventListener("current-wallet-changed", this.$options.onWalletChangedH);

    if (this.$options.focusTrap) {
      this.$options.focusTrap.destroy();
    }

    this.onOpen();
  },
  watch: {
    display: function () {
      if (this.display) {
        this.$options.focusTrap.activate();
        this.onOpen();
      } else {
        this.$options.focusTrap.deactivate();
      }
    },
    walletAddress: function () {
      this.loadBalance();
    },
  },
});
