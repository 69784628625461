// Address utils

"use strict";

export function validateAddress(addr: string) : boolean {
    return addr && addr.length === 42 && (/^0x[0-9a-f]+$/i).test(addr);
}

export function compareAddresses(addr1: string, addr2: string): boolean {
    return (addr1 + "").toLowerCase() === (addr2 + "").toLowerCase();
}

export function addressIsVoid(addr: string): boolean {
    return compareAddresses(addr, "0x0000000000000000000000000000000000000000");
}
