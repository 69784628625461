
import { PropType, defineComponent } from "vue";
import { NotificationData } from "@/api/api-notifications";
import { renderDateAndTime } from "@/utils/time-utils";
import { AuditoryStatus } from "@/api/api-certification-bodies";
import { escapeHTML } from "@/utils/text";
import { GetAPIURL } from "@/utils/request";
import { Request } from "@/utils/request";
import { UserDetails, UsersAdminAPI } from "@/api/api-users-admin";
import { AppEvents } from "@/control/app-events";
import { Timeouts } from "@/utils/timeout";

// character limit for the name of projects
const MAX_PROJECT_NAME_LENGTH = 20;

export default defineComponent({
  name: "NotificationContent",
  props: {
    id: String,
    n: Object as PropType<NotificationData>,
  },
  methods: {
    renderDate: function (ts: number): string {
      return renderDateAndTime(ts, this.$t);
    },

    renderAuditoryStatus: function (s: AuditoryStatus): string {
      switch (s) {
        case "Passed":
          return this.$t("Passed");
        case "NotStarted":
          return this.$t("Pending");
        case "InProgress":
          return this.$t("In progress");
        case "Failed":
          return this.$t("Failed");
        case "Cancelled":
          return this.$t("Cancelled");
        default:
          return this.$t("Cancelled");
      }
    },

    getNotificationDocument: function (notificationId: string, documentName: string) {
      return GetAPIURL("/notifications/document/" + encodeURIComponent(notificationId) + "/" + encodeURIComponent(documentName));
    },

    renderMessage: function (msg: string): string {
      return msg.split("\n").map(line => {
        const lineParts = line.split(":");

        let name = lineParts[0] + "";
        const val = (lineParts[1] || "-").trim();

        switch (name) {
          case "Full name":
            name = this.$t("Full name");
            break;
          case "Country":
            name = this.$t("Country");
            break;
          case "Contact email":
            name = this.$t("Contact email");
            break;
          case "Contact phone":
            name = this.$t("Contact phone");
            break;
          case "Website":
            name = this.$t("Website");
            break;
          case "Organization name":
            name = this.$t("Organization name");
            break;
        }

        return name + ": " + val;

      }).filter(a => !!a).map(escapeHTML).join("<br>");
    },

    checkPermission: function (uid: string, permission: string): boolean {

      let permissionConverted: string[] = [];

      switch (permission) {
        case "ProjectOperator":
          permissionConverted = ['projects'];
          break;
        case "CertificationBody":
          permissionConverted = ['cert_body'];
          break;
        case "CertificationScheme":
          permissionConverted = ['cert_scheme'];
          break;
      }

      let check = false;
      Request.Pending(
        "check-user-permission-load",
        UsersAdminAPI.GetUser(uid)
      )
        .onSuccess((user: UserDetails) => {
          if(user.permissions.includes(permissionConverted[0])) 
            check = true;
        })
        .onRequestError((err) => {
          Request.ErrorHandler()
            .add(401, "*", () => {
              AppEvents.Emit("unauthorized");
            })
            .add(403, "*", () => {
              this.$router.push({ name: "home" });
            })
            .add(404, "*", () => {
              this.$router.push({ name: "home" });
            })
            .add("*", "*", () => {
              Timeouts.Set("check-user-permission-load", 2000, this.load.bind(this));
            })
            .handle(err);
        })
        .onUnexpectedError((err) => {
          console.error(err);
          Timeouts.Set("check-user-permission-load", 2000, this.load.bind(this));
        });
        return check;
    },
  },
  computed: {
    truncatedProjectName(): string {
      if (!this.n || !this.n.projectName) return '';

      const words = this.n.projectName.split(' ');
      const longWord = words.find(word => word.length > MAX_PROJECT_NAME_LENGTH);

      if (longWord) {
        return longWord.slice(0, MAX_PROJECT_NAME_LENGTH) + '...';
      } else {
        return this.n.projectName;
      }
    }
  }
});
