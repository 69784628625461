
import { AppEvents } from "@/control/app-events";
import { defineComponent, nextTick } from "vue";
import { useVModel } from "@/utils/vmodel";
import { FocusTrap } from "@/utils/focus-trap";
import { WalletsController } from "@/control/wallets";
import { WalletInfo } from "@/api/api-wallet";
import { AuthController } from "@/control/auth";

export default defineComponent({
  name: "SelectWalletModal",
  emits: ["update:display"],
  props: {
    display: Boolean,
  },
  setup(props) {
    return {
      displayStatus: useVModel(props, "display"),
    };
  },
  data: function () {
    return {
      current: WalletsController.CurrentWalletId,
      wallets: WalletsController.GetWallets(),
    };
  },
  methods: {
    open: function () {
      this.displayStatus = true;
    },

    close: function () {
      this.displayStatus = false;
    },

    renderOrganizationName: function (id: string): string {
      const org = AuthController.FindOrganization(id);

      if (!org) {
        return this.$t("Unnamed organization");
      }

      return org.name;
    },

    escapeToClose: function (event) {
      if (event.key === "Escape") {
        this.close();
      }
    },

    stopPropagationEvent: function (e) {
      e.stopPropagation();
    },

    clickOnEnter: function (event) {
      if (event.key === "Enter") {
        event.preventDefault();
        event.stopPropagation();
        event.target.click();
      }
    },

    onCurrentWalletChanged: function () {
      this.current = WalletsController.CurrentWalletId;
    },

    onWalletListChanged: function () {
      this.wallets = WalletsController.GetWallets();
    },

    goBack: function () {
      this.close();
      AppEvents.Emit("wallet-settings-open");
    },

    selectCurrentWallet: function (w: WalletInfo) {
      WalletsController.SetDefaultWallet(w);
      this.goBack();
    },

    renderWalletAddress: function (a: string) {
      return a.substring(0, 8) + "..." + a.substring(a.length - 8);
    },
  },
  mounted: function () {
    this.$options.openH = this.open.bind(this);
    AppEvents.AddEventListener("select-wallet-open", this.$options.openH);

    this.$options.onCurrentWalletChangedH = this.onCurrentWalletChanged.bind(this);
    AppEvents.AddEventListener("current-wallet-changed", this.$options.onCurrentWalletChangedH);

    this.$options.onWalletListChangedH = this.onWalletListChanged.bind(this);
    AppEvents.AddEventListener("wallet-list-changed", this.$options.onWalletListChangedH);

    this.$options.focusTrap = new FocusTrap(this.$el, this.close.bind(this));
  },
  beforeUnmount: function () {
    AppEvents.RemoveEventListener("select-wallet-open", this.$options.openH);
    AppEvents.RemoveEventListener("current-wallet-changed", this.$options.onCurrentWalletChangedH);
    AppEvents.RemoveEventListener("wallet-list-changed", this.$options.onWalletListChangedH);
    this.$options.focusTrap.destroy();
  },
  watch: {
    display: function () {
      if (this.display) {
        this.$options.focusTrap.activate();
        nextTick(() => {
          this.$el.focus();
        });
      } else {
        this.$options.focusTrap.deactivate();
      }
    },
  },
});
