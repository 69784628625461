// Currency token API

import { GenerateURIQuery, GetAPIURL, RequestParams } from "@/utils/request";
import { UserWallet } from "./api-operator-projects";

export interface TokenContractData {
    address: string;
    name: string; 
    symbol: string;
    decimals: number;
    totalSupply: string;
    root: string;
    admins: string[];
}

export interface BalanceResponse {
    balance: string;
    symbol: string;
    decimals: number;
}

export interface CurrencyTokenTransaction {
    id: string;
    block: string;
    hash: string;
    date: number;
    from: string;
    to: string;
    value: string;
}

export class CurrencyTokenAPI {
    public static GetMetadata(): RequestParams<TokenContractData> {
        return {
            method: "GET",
            url: GetAPIURL("/token"),
        };
    }

    public static GetBalance(address: string): RequestParams<BalanceResponse> {
        return {
            method: "GET",
            url: GetAPIURL("/token/balance/" + encodeURIComponent(address)),
        };
    }

    public static GetTransactions(options: { to_date?: number, limit?: number, wallet?: string }): RequestParams<CurrencyTokenTransaction[]> {
        return {
            method: "GET",
            url: GetAPIURL("/token/transactions" + GenerateURIQuery(options)),
        };
    }

    public static Mint(options: { to: string, value: string }, wallet: UserWallet): RequestParams<void> {
        return {
            method: "POST",
            url: GetAPIURL("/token/mint"),
            json: options,
            headers: {
                'x-wallet-id': wallet.id,
                'x-wallet-password': wallet.password,
            },
        };
    }

    public static Transfer(options: { to: string, value: string }, wallet: UserWallet): RequestParams<void> {
        return {
            method: "POST",
            url: GetAPIURL("/token/transfer"),
            json: options,
            headers: {
                'x-wallet-id': wallet.id,
                'x-wallet-password': wallet.password,
            },
        };
    }

    public static Burn(options: { amount: string }, wallet: UserWallet): RequestParams<void> {
        return {
            method: "POST",
            url: GetAPIURL("/token/burn"),
            json: options,
            headers: {
                'x-wallet-id': wallet.id,
                'x-wallet-password': wallet.password,
            },
        };
    }

    public static TransferRoot(options: { address: string }, wallet: UserWallet): RequestParams<void> {
        return {
            method: "POST",
            url: GetAPIURL("/token/root"),
            json: options,
            headers: {
                'x-wallet-id': wallet.id,
                'x-wallet-password': wallet.password,
            },
        };
    }

    public static GrantAdmin(options: { address: string }, wallet: UserWallet): RequestParams<void> {
        return {
            method: "POST",
            url: GetAPIURL("/token/admin/grant"),
            json: options,
            headers: {
                'x-wallet-id': wallet.id,
                'x-wallet-password': wallet.password,
            },
        };
    }

    public static RevokeAdmin(options: { address: string }, wallet: UserWallet): RequestParams<void> {
        return {
            method: "POST",
            url: GetAPIURL("/token/admin/revoke"),
            json: options,
            headers: {
                'x-wallet-id': wallet.id,
                'x-wallet-password': wallet.password,
            },
        };
    }
}