// Notification API

import { GenerateURIQuery, GetAPIURL, RequestParams } from "@/utils/request";
import { EntityType } from "./api-penalties";
import { AuditoryStatus } from "./api-certification-bodies";

/**
 * Project registered in scheme
 * Notify: Scheme operators, Project operators, Accredited certification body operators.
 */
export interface NotificationData_Registered_Project_In_Scheme {
    type: "project_registered_in_scheme";
    projectId: string;
    projectName: string;
    schemeId: string;
    schemeName: string;
}

/**
 * Project approved in scheme
 * Notify: Scheme operators, Project operators, Accredited certification body operators.
 */
export interface NotificationData_Approved_Project_For_Scheme {
    type: "project_approved_in_scheme";
    projectId: string;
    projectName: string;
    schemeId: string;
    schemeName: string;
}

/**
 * Project unapproved in scheme
 * Notify: Scheme operators, Project operators, Accredited certification body operators.
 */
export interface NotificationData_Unapproved_Project_For_Scheme {
    type: "project_unapproved_in_scheme";
    projectId: string;
    projectName: string;
    schemeId: string;
    schemeName: string;
}

/**
 * Body registered in scheme
 * Notify: Scheme operators, Certification body operators.
 */
export interface NotificationData_Registered_Body_In_Scheme {
    type: "body_registered_in_scheme";
    bodyId: string;
    bodyName: string;
    schemeId: string;
    schemeName: string;
}

/**
 * Body accredited in scheme
 * Notify: Scheme operators, Certification body operators.
 */
export interface NotificationData_Accredited_Body_In_Scheme {
    type: "body_accredited_in_scheme";
    bodyId: string;
    bodyName: string;
    schemeId: string;
    schemeName: string;
}

/**
 * Body unaccredited in scheme
 * Notify: Scheme operators, Certification body operators.
 */
export interface NotificationData_Unaccredited_Body_In_Scheme {
    type: "body_unaccredited_in_scheme";
    bodyId: string;
    bodyName: string;
    schemeId: string;
    schemeName: string;
}

/**
 * New auditory
 * Notify: Scheme operators, Certification body operators and project operators.
 */
export interface NotificationData_Auditory_Created {
    type: "auditory_created";
    auditoryIndex: number;
    auditoryDate: number;
    projectId: string;
    projectName: string;
    bodyId: string;
    bodyName: string;
    schemeId: string;
    schemeName: string;
}

/**
 * Auditory status change
 * Notify: Scheme operators, Certification body operators and project operators.
 */
export interface NotificationData_Auditory_Status_Changed {
    type: "auditory_status_changed";
    auditoryIndex: number;
    auditoryStatus: AuditoryStatus;
    projectId: string;
    projectName: string;
    bodyId: string;
    bodyName: string;
    schemeId: string;
    schemeName: string;
}

/**
 * Carbon credit created
 * Notify: Project operators, body operators
 */
export interface NotificationData_Carbon_Credit_Created {
    type: "carbon_credit_created";
    creditId: string;
    amountText: string;
    projectId: string;
    projectName: string;
    schemeId: string;
    schemeName: string;
}

/**
 * Carbon credit certified
 * Notify: Project operators, accredited certification body operators
 */
export interface NotificationData_Carbon_Credit_Certified {
    type: "carbon_credit_certified";
    creditId: string;
    amountText: string;
    projectId: string;
    projectName: string;
    schemeId: string;
    schemeName: string;
    bodyId: string;
    bodyName: string;
}

/**
 * Carbon credit not certified
 * Notify: Project operators, accredited certification body operators
 */
export interface NotificationData_Carbon_Credit_Not_Certified {
    type: "carbon_credit_not_certified";
    creditId: string;
    amountText: string;
    projectId: string;
    projectName: string;
    schemeId: string;
    schemeName: string;
    bodyId: string;
    bodyName: string;
    reason: string;
}


/**
 * Carbon credit listed for sale
 * Notify: Project operators
 */
export interface NotificationData_Carbon_Credit_Listed {
    type: "carbon_credit_listed";
    creditId: string;
    amountText: string;
    priceText: string;
    projectId: string;
    projectName: string;
    schemeId: string;
    schemeName: string;
}

/**
 * Carbon credit delisted
 * Notify: Project operators
 */
export interface NotificationData_Carbon_Credit_Delisted {
    type: "carbon_credit_delisted";
    creditId: string;
    amountText: string;
    projectId: string;
    projectName: string;
    schemeId: string;
    schemeName: string;
    reason: string;
}

/**
 * Carbon credit sold
 * Notify: Project operators
 */
export interface NotificationData_Carbon_Credit_Sold {
    type: "carbon_credit_sold";
    creditId: string;
    amountText: string;
    priceText: string;
    projectId: string;
    projectName: string;
    schemeId: string;
    schemeName: string;
    owner: string;
}


/**
 * Carbon credit bought
 * Notify: Buyer
 */
export interface NotificationData_Carbon_Credit_Bought {
    type: "carbon_credit_bought";
    creditId: string;
    amountText: string;
    priceText: string;
    projectId: string;
    projectName: string;
    schemeId: string;
    schemeName: string;
    owner: string;
}

/**
 * Carbon credit retired
 * Notify: Owner of the credit
 */
export interface NotificationData_Carbon_Credit_Retired {
    type: "carbon_credit_retired";
    creditId: string;
    amountText: string;
    owner: string;
}

/**
 * Carbon credits retired
 * Notify: Owner of the credits
 */
export interface NotificationData_Carbon_Credit_Retired_All {
    type: "carbon_credit_retired_all";
}

/**
 * Received money
 * Notify: Wallet owner
 */
export interface NotificationData_Token_Transaction_In {
    type: "token_transaction_in";
    walletAddress: string;
    from: string;
    amountText: string;
}

/**
 * Sent money
 * Notify: Wallet owner
 */
export interface NotificationData_Token_Transaction_Out {
    type: "token_transaction_out";
    walletAddress: string;
    to: string;
    amountText: string;
}

/**
 * Penalty applied
 * Notify: Penalty operators and Entity operators
 */
export interface NotificationData_Penalty_Applied {
    type: "penalty_applied";
    penaltyId: string;
    entityType: EntityType;
    entityId: string;
    entityName: string;
    amountText: string;
    reason: string;
}

/**
 * Penalty cleared
 * Notify: Penalty operators and Entity operators
 */
export interface NotificationData_Penalty_Cleared {
    type: "penalty_cleared";
    penaltyId: string;
    entityType: EntityType;
    entityId: string;
    entityName: string;
}

/**
 * Admin request
 * Notify: Administrators
 */
export interface NotificationData_Admin_Request_Role {
    type: "admin_request_role";
    uid: string;
    username: string;
    email: string;
    role: "ProjectOperator" | "CertificationScheme" | "CertificationBody";
    message: string;
    document: string;
    documentName: string;
    handled: boolean;
}

/**
 * Role request declined
 * Notify: User who requested the role
 */
export interface NotificationData_Decline_Request_Role {
    type: "decline_request_role";
    reason: string;
}

/**
 * Role request accepted
 * Notify: User who requested the role
 */
export interface NotificationData_Accept_Request_Role {
    type: "accept_request_role";
}

export interface NotificationData_Unknown {
    type: "unknown";
}

export type NotificationData = NotificationData_Unknown
    // Project
    | NotificationData_Registered_Project_In_Scheme
    | NotificationData_Approved_Project_For_Scheme
    | NotificationData_Unapproved_Project_For_Scheme
    // Body
    | NotificationData_Registered_Body_In_Scheme
    | NotificationData_Accredited_Body_In_Scheme
    | NotificationData_Unaccredited_Body_In_Scheme
    // Auditory
    | NotificationData_Auditory_Created
    | NotificationData_Auditory_Status_Changed
    // Carbon credits
    | NotificationData_Carbon_Credit_Created
    | NotificationData_Carbon_Credit_Certified
    | NotificationData_Carbon_Credit_Not_Certified
    | NotificationData_Carbon_Credit_Listed
    | NotificationData_Carbon_Credit_Delisted
    | NotificationData_Carbon_Credit_Sold
    | NotificationData_Carbon_Credit_Bought
    | NotificationData_Carbon_Credit_Retired
    | NotificationData_Carbon_Credit_Retired_All
    // Token transactions
    | NotificationData_Token_Transaction_In
    | NotificationData_Token_Transaction_Out
    // Penalties
    | NotificationData_Penalty_Applied
    | NotificationData_Penalty_Cleared
    // Admin requests
    | NotificationData_Admin_Request_Role
    | NotificationData_Decline_Request_Role
    | NotificationData_Accept_Request_Role;

export interface PlatformNotification {
    id: string;
    date: number;
    data: NotificationData;
}

export class NotificationsAPI {
    public static GetStatus(): RequestParams<{ unread: number, total: number }> {
        return {
            method: "GET",
            url: GetAPIURL("/notifications"),
        };
    }

    public static List(oldest: number): RequestParams<PlatformNotification[]> {
        return {
            method: "GET",
            url: GetAPIURL("/notifications/list") + GenerateURIQuery({ oldest: oldest }),
        };
    }

    public static GetNotification(id: string): RequestParams<PlatformNotification> {
        return {
            method: "GET",
            url: GetAPIURL("/notifications/") + encodeURIComponent(id),
        };
    }

    public static Read(timestamp: number): RequestParams<void> {
        return {
            method: "POST",
            url: GetAPIURL("/notifications/read"),
            json: {
                timestamp: timestamp
            },
        };
    }

    public static Delete(id: string): RequestParams<void> {
        return {
            method: "DELETE",
            url: GetAPIURL("/notifications/id/" + encodeURIComponent(id)),
        };
    }
}