// Organization APIs

import { GetAPIURL, RequestParams } from "@/utils/request";

export interface OrganizationMember {
    uid: string;
    username: string;
    email: string;
    owner: boolean;
    admin: boolean;
}

export interface OrganizationInvite {
    uid: string;
    username: string;
    email: string;
    admin: boolean;
    date: number;
}

export interface Organization {
    id: string;
    name: string;
    members: OrganizationMember[];
    invites: OrganizationInvite[];
}

export class OrganizationAPI {
    public static CreateOrganization(options: {name: string}): RequestParams<{ id: string }> {
        return {
            method: "POST",
            url: GetAPIURL("/organizations"),
            json: options,
        };
    }

    public static GetOrganization(id: string): RequestParams<Organization> {
        return {
            method: "GET",
            url: GetAPIURL("/organizations/" + encodeURIComponent(id)),
        };
    }

    public static RenameOrganization(id: string, options: {name: string}): RequestParams<void> {
        return {
            method: "POST",
            url: GetAPIURL("/organizations/" + encodeURIComponent(id)),
            json: options,
        };
    }

    public static SetMemberRole(id: string, uid: string, options: {owner: boolean, admin: boolean}): RequestParams<void> {
        return {
            method: "POST",
            url: GetAPIURL("/organizations/" + encodeURIComponent(id) + "/members/" + encodeURIComponent(uid)),
            json: options,
        };
    }

    public static RemoveMember(id: string, uid: string): RequestParams<void> {
        return {
            method: "DELETE",
            url: GetAPIURL("/organizations/" + encodeURIComponent(id) + "/members/" + encodeURIComponent(uid)),
        };
    }

    public static Invite(id: string, options: {email: string, admin: boolean}): RequestParams<void> {
        return {
            method: "POST",
            url: GetAPIURL("/organizations/" + encodeURIComponent(id) + "/invites"),
            json: options,
        };
    }

    public static RemoveInvite(id: string, uid: string): RequestParams<void> {
        return {
            method: "DELETE",
            url: GetAPIURL("/organizations/" + encodeURIComponent(id) + "/invites/" + encodeURIComponent(uid)),
        };
    }

    public static RemoveOrganization(id: string): RequestParams<void> {
        return {
            method: "DELETE",
            url: GetAPIURL("/organizations/" + encodeURIComponent(id)),
        };
    }
}