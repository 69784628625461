
import { AppEvents } from "@/control/app-events";
import { AppPreferences } from "@/control/app-preferences";
import { AuthController } from "@/control/auth";
import { defineComponent, nextTick } from "vue";
import { useVModel } from "../../utils/vmodel";
import { FocusTrap } from "../../utils/focus-trap";
import { Request } from "@/utils/request";
import { OffsetRegistryAPI } from "@/api/api-registry";
import { Timeouts } from "@/utils/timeout";
import { WalletsController } from "@/control/wallets";
import { compareAddresses } from "@/utils/address";
import { copyAddressUtil, truncateHash } from "@/utils/copy-address";
const LOAD_REGISTRY_ADMINS_REQUEST_ID = "registry-admins-menu-load";

export default defineComponent({
  name: "AccountSettingsModal",
  emits: ["update:display"],
  props: {
    display: Boolean,
  },
  setup(props) {
    return {
      displayStatus: useVModel(props, "display"),
    };
  },
  data: function () {
    return {
      email: AuthController.Email,
      username: AuthController.Username,
      tfa: AuthController.RequiresTwoFactorAuthentication,
      admin: AuthController.GlobalAdmin,
      languages: AppPreferences.AvailableLanguages.slice(),
      lang: AppPreferences.Language,
      isDarkTheme: AppPreferences.Theme === "dark",

      walletAddress: WalletsController.CurrentWalletAddress,

      canProjects: AuthController.CanRegisterProjects(),
      canBodies: AuthController.CanRegisterCertificationBodies(),
      canSchemes: AuthController.CanRegisterCertificationSchemes(),

      selectedOrganization: AuthController.SelectedOrganization,
      selectedOrganizationName: AuthController.SelectedOrganizationMembership ? AuthController.SelectedOrganizationMembership.name : "",
      registryRoot: "",
      registryAdmins: [],
      showHoverTool: false,
    };
  },
  methods: {

    copyAddressUtil,
    truncateHash,

    load: function () {
      Request.Pending(
        LOAD_REGISTRY_ADMINS_REQUEST_ID,
        OffsetRegistryAPI.GetMetadata()
      )
        .onSuccess((metadata) => {
          this.registryRoot = metadata.root.toLowerCase();
          this.registryAdmins = metadata.admins.map(a => a.toLowerCase());
          if (!this.registryAdmins.includes(this.registryRoot)) {
            this.registryAdmins.unshift(this.registryRoot);
          }
        })
        .onRequestError((err) => {
          Request.ErrorHandler()
            .add(401, "*", () => {
              AppEvents.Emit("unauthorized");
            })
            .add(403, "*", () => {
                this.$router.push({ name: "home" });
            })
            .add(404, "*", () => {
              this.notFound = true;
              this.loading = false;
            })
            .add("*", "*", () => {
              Timeouts.Set(LOAD_REGISTRY_ADMINS_REQUEST_ID, 2000, this.load.bind(this));
            })
            .handle(err);
        })
        .onUnexpectedError((err) => {
          console.error(err);
          Timeouts.Set(LOAD_REGISTRY_ADMINS_REQUEST_ID, 2000, this.load.bind(this));
        });
    },

    open: function () {
      this.displayStatus = true;
      this.lang = AppPreferences.Language;
      this.isDarkTheme = AppPreferences.Theme === "dark";
      this.load();
    },

    close: function () {
      this.displayStatus = false;
    },

    escapeToClose: function (event) {
      if (event.key === "Escape") {
        this.close();
      }
    },

    logout: function () {
      AppEvents.Emit("logout-open");
    },

    stopPropagationEvent: function (e) {
      e.stopPropagation();
    },

    onAuthChanged: function () {
      this.email = AuthController.Email;
      this.username = AuthController.Username;
      this.tfa = AuthController.RequiresTwoFactorAuthentication;
      this.admin = AuthController.GlobalAdmin;

      this.canProjects = AuthController.CanRegisterProjects();
      this.canBodies = AuthController.CanRegisterCertificationBodies();
      this.canSchemes = AuthController.CanRegisterCertificationSchemes();

      this.selectedOrganization = AuthController.SelectedOrganization;
      this.selectedOrganizationName = AuthController.SelectedOrganizationMembership ? AuthController.SelectedOrganizationMembership.name : "";
    },

    onWalletChanged: function () {
      this.walletAddress = WalletsController.CurrentWalletAddress;
    },

    clickOnEnter: function (event) {
      if (event.key === "Enter") {
        event.preventDefault();
        event.stopPropagation();
        event.target.click();
      }
    },

    renderLanguage: function (lang, languages) {
      for (let l of languages) {
        if (l.id === lang) {
          return l.name;
        }
      }
      return "-";
    },

    openSelectLanguage: function () {
      this.close();
      AppEvents.Emit("select-language-open");
    },

    openWalletSettings: function () {
      AppEvents.Emit("wallet-settings-open");
    },

    openProfileSettings: function () {
      this.$router.push({ name: "profile" });
    },

    openFootprintHistory: function () {
      this.$router.push({ name: "footprint" });
    },

    openMyProjects: function () {
      this.$router.push({ name: "projects", query: { filter: 'myProjects' } });
    },

    openProfileVerification: function () {
      this.$router.push({ name: "verify-profile", params: { mode: "none" } });
    },

    openChangeUsername: function () {
      AppEvents.Emit("change-username-open");
    },

    openChangeEmail: function () {
      AppEvents.Emit("change-email-open");
    },

    openChangePassword: function () {
      AppEvents.Emit("change-password-open");
    },

    openAccountDelete: function () {
      AppEvents.Emit("delete-account-open");
    },

    openDevicesList: function () {
      AppEvents.Emit("devices-list-open");
    },

    enableTFAOpen: function () {
      AppEvents.Emit("tfa-enable-open");
    },

    disableTFAOpen: function () {
      AppEvents.Emit("tfa-disable-open");
    },

    openOrganizationSettings: function () {
      AppEvents.Emit("organization-settings-open");
    },

    isAdmin: function (
      wallet: string,
      root: string,
      admins: string[]
    ): boolean {
      if (compareAddresses(root, wallet)) {
        return true;
      }

      for (let admin of admins) {
        if (compareAddresses(admin, wallet)) {
          return true;
        }
      }

      return false;
    },
  },
  mounted: function () {
    this.$options.openH = this.open.bind(this);
    AppEvents.AddEventListener("account-settings-open", this.$options.openH);

    this.$options.onAuthChangedH = this.onAuthChanged.bind(this);
    AppEvents.AddEventListener(
      "auth-status-changed",
      this.$options.onAuthChangedH
    );

    this.$options.onWalletChangedH = this.onWalletChanged.bind(this);
    AppEvents.AddEventListener(
      "current-wallet-changed",
      this.$options.onWalletChangedH
    );

    this.$options.focusTrap = new FocusTrap(this.$el, this.close.bind(this));

    this.load();
  },
  beforeUnmount: function () {
    AppEvents.RemoveEventListener("account-settings-open", this.$options.openH);
    AppEvents.RemoveEventListener(
      "auth-status-changed",
      this.$options.onAuthChangedH
    );
    AppEvents.RemoveEventListener(
      "current-wallet-changed",
      this.$options.onWalletChangedH
    );
    this.$options.focusTrap.destroy();
  },
  watch: {
    display: function () {
      if (this.display) {
        this.$options.focusTrap.activate();
        nextTick(() => {
          this.$el.focus();
        });
      } else {
        this.$options.focusTrap.deactivate();
      }
    },
  },
});
