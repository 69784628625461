// Payment API

import { GenerateURIQuery, GetAPIURL, RequestParams } from "@/utils/request";
import { UserWallet } from "./api-operator-projects";

export class PaymentAPI {
    public static AddBalance(options: {amount: string, method: "card"}, wallet: UserWallet): RequestParams<{url: string}> {
        return {
            method: "POST",
            url: GetAPIURL("/payment/balance/add"),
            json: options,
            headers: {
                'x-wallet-id': wallet.id,
                'x-wallet-password': wallet.password,
            },
        };
    }

    public static WithdrawBalance(options: {amount: string}, wallet: UserWallet): RequestParams<{transferCode: string}> {
        return {
            method: "POST",
            url: GetAPIURL("/payment/balance/withdraw" + GenerateURIQuery(options)),
            json: options,
            headers: {
                'x-wallet-id': wallet.id,
                'x-wallet-password': wallet.password,
            },
        };
    }
}