
import { AppEvents } from "@/control/app-events";
import { defineComponent, nextTick } from "vue";
import { useVModel } from "../../utils/vmodel";
import { FocusTrap } from "../../utils/focus-trap";
import { Request } from "@/utils/request";
import { getUniqueStringId } from "@/utils/unique-id";
import { NotificationsAPI, PlatformNotification } from "@/api/api-notifications";
import { Timeouts } from "@/utils/timeout";
import { NotificationsController } from "@/control/notifications";
import { renderDateAndTime } from "@/utils/time-utils";

import NotificationContent from "../utils/NotificationContent.vue";

const MAX_NOTIFICATIONS_IN_MODAL = 3;

export default defineComponent({
  name: "NotificationsModal",
  components: {
    NotificationContent,
  },
  emits: ["update:display"],
  props: {
    display: Boolean,
  },
  setup(props) {
    return {
      loadRequestId: getUniqueStringId(),
      displayStatus: useVModel(props, "display"),
    };
  },
  data: function () {
    return {
      loading: true,
      notifications: [] as PlatformNotification[],
      notificationsUnread: NotificationsController.UnreadCount,

      busyRead: false,
    };
  },
  methods: {
    open: function () {
      this.displayStatus = true;
    },

    load: function () {
      this.loading = true;

      Timeouts.Abort(this.loadRequestId);

      Request.Pending(this.loadRequestId, NotificationsAPI.List(Date.now()))
        .onSuccess(list => {
          this.loading = false;
          this.notifications = list.slice(0, MAX_NOTIFICATIONS_IN_MODAL);
        })
        .onRequestError(err => {
          Request.ErrorHandler()
            .add(401, "*", () => {
              AppEvents.Emit("unauthorized");
            })
            .add("*", "*", () => {
              // Retry
              Timeouts.Set(this.loadRequestId, 2000, this.load.bind(this));
            })
            .handle(err);
        }).onUnexpectedError(err => {
          console.error(err);
          // Retry
          Timeouts.Set(this.loadRequestId, 2000, this.load.bind(this));
        });
    },

    close: function () {
      this.displayStatus = false;
    },

    escapeToClose: function (event) {
      if (event.key === "Escape") {
        this.close();
      }
    },

    stopPropagationEvent: function (e) {
      e.stopPropagation();
    },

    clickOnEnter: function (event) {
      if (event.key === "Enter") {
        event.preventDefault();
        event.stopPropagation();
        event.target.click();
      }
    },

    goBack: function () {
      this.close();
    },

    onNotificationsChanged: function () {
      this.notificationsUnread = NotificationsController.UnreadCount;
    },

    viewMore: function () {
      this.$router.push('/notifications');
      this.close();
    },

    markRead: function () {
      if (this.busyRead) {
        return;
      }
      this.busyRead = true;
      Request.Do(NotificationsAPI.Read(Date.now())).onSuccess(() => {
        this.busyRead = false;
        this.unread = 0;
        NotificationsController.Load();
      }).onRequestError(err => {
        this.busyRead = false;
        Request.ErrorHandler()
          .add(401, "*", () => {
            AppEvents.Emit("unauthorized");
          })
          .handle(err);
      }).onUnexpectedError(err => {
        this.busyRead = false;
        console.error(err);
      });
    },

    renderDateTime: function (t: number) {
      return renderDateAndTime(t, this.$t);
    },
  },
  mounted: function () {
    this.$options.openH = this.open.bind(this);
    AppEvents.AddEventListener("notifications-open", this.$options.openH);

    this.$options.onNotificationsChangedH = this.onNotificationsChanged.bind(this);
    AppEvents.AddEventListener("notifications-status-changed", this.$options.onNotificationsChangedH);

    this.$options.focusTrap = new FocusTrap(this.$el, this.close.bind(this));
  },
  beforeUnmount: function () {
    Timeouts.Abort(this.loadRequestId);
    Request.Abort(this.loadRequestId);

    AppEvents.RemoveEventListener("notifications-open", this.$options.openH);
    AppEvents.RemoveEventListener("notifications-status-changed", this.$options.onNotificationsChangedH);
    this.$options.focusTrap.destroy();
  },
  watch: {
    display: function () {
      if (this.display) {
        this.load();
        this.$options.focusTrap.activate();
        nextTick(() => {
          this.$el.focus();
        });
      } else {
        Timeouts.Abort(this.loadRequestId);
        Request.Abort(this.loadRequestId);
        this.$options.focusTrap.deactivate();
      }
    },
  },
});
