// Account API

import { GetAPIURL, RequestParams } from "@/utils/request";

export interface ActiveSession {
    session: string;
    created: number;
    remote: string;
    os: string;
    browser: string;
    current: boolean;
}

export interface AccountOrganizationInvite {
    id: string;
    name: string;
    admin: boolean;
    date: number;
}

export class AccountAPI {
    public static ChangeUsername(username: string, tfaToken: string): RequestParams<void> {
        return {
            method: "POST",
            url: GetAPIURL("/account/username"),
            json: {
                username: username,
                tfa_token: tfaToken,
            },
        };
    }

    public static ChangeEmail(email: string, tfaToken: string): RequestParams<void> {
        return {
            method: "POST",
            url: GetAPIURL("/account/email"),
            json: {
                email: email,
                tfa_token: tfaToken,
            },
        };
    }

    public static ChangePassword(oldPassword: string, newPassword: string): RequestParams<void> {
        return {
            method: "POST",
            url: GetAPIURL("/account/password"),
            json: {
                old_password: oldPassword,
                new_password: newPassword,
            },
        };
    }

    public static GenerateTwoFactorAuthentication(): RequestParams<{secret: string, uri: string}> {
        return {
            method: "GET",
            url: GetAPIURL("/account/tfa/generate"),
        };
    }

    public static SetupTwoFactorAuthentication(secret: string, token: string): RequestParams<void> {
        return {
            method: "POST",
            url: GetAPIURL("/account/tfa/setup"),
            json: {
                secret: secret,
                token: token,
            },
        };
    }

    public static DisableTwoFactorAuthentication(token: string): RequestParams<void> {
        return {
            method: "POST",
            url: GetAPIURL("/account/tfa/disable"),
            json: {
                token: token,
            },
        };
    }

    public static GetActiveSessions(): RequestParams<ActiveSession[]> {
        return {
            method: "GET",
            url: GetAPIURL("/account/sessions"),
        };
    }

    public static CloseAllSessions(): RequestParams<void> {
        return {
            method: "POST",
            url: GetAPIURL("/account/sessions/close"),
        };
    }

    public static GetInvites(): RequestParams<AccountOrganizationInvite[]> {
        return {
            method: "GET",
            url: GetAPIURL("/account/invites"),
        };
    }

    public static AcceptInvite(id: string): RequestParams<void> {
        return {
            method: "POST",
            url: GetAPIURL("/account/invites/" + encodeURIComponent(id) + "/accept"),
        };
    }

    public static RemoveInvite(id: string): RequestParams<void> {
        return {
            method: "DELETE",
            url: GetAPIURL("/account/invites/" + encodeURIComponent(id) + ""),
        };
    }

    public static LeaveOrganization(id: string): RequestParams<void> {
        return {
            method: "DELETE",
            url: GetAPIURL("/account/organizations/" + encodeURIComponent(id) + ""),
        };
    }

    public static RequestRoleChange(role: "ProjectOperator" | "CertificationScheme" | "CertificationBody", message: string, document: File): RequestParams<void> {
        const form = new FormData();
        form.append("role", role);
        form.append("message", message);
        form.append("document", document);
        
        return {
            method: "POST",
            url: GetAPIURL("/account/role/request"),
            form: form,
        };
    }

    public static CheckRequestRole(): RequestParams<boolean> {
        return {
            method: "GET",
            url: GetAPIURL("/account/role/check"),
        };
    }

    public static RequestInit(role: "ProjectOperator" | "CertificationScheme" | "CertificationBody"): RequestParams<void> {
        const form = new FormData();
        form.append("role", role);
        
        return {
            method: "POST",
            url: GetAPIURL("/account/role/request"),
            form: form,
        };
    }

    public static DeleteAccount(password: string, tfaToken: string): RequestParams<void> {
        return {
            method: "POST",
            url: GetAPIURL("/account/delete"),
            json: {
                password: password,
                tfa_token: tfaToken,
            },
        };
    }
}
