// Wallet API

import { GetAPIURL, RequestParams } from "@/utils/request";
import { UserWallet } from "./api-operator-projects";

export interface WalletInfoCreation {
    id: string;
    name: string;
    address: string;
    organization: string;
    privateKey: string;
}

export interface WalletInfo {
    id: string;
    name: string;
    address: string;
    organization: string;
}

export class WalletAPI {
    public static ListWallets(): RequestParams<WalletInfo[]> {
        return {
            method: "GET",
            url: GetAPIURL("/wallet"),
        };
    }

    public static GetWallet(wid: string): RequestParams<WalletInfo> {
        return {
            method: "GET",
            url: GetAPIURL("/wallet/" + encodeURIComponent(wid)),
        };
    }

    public static CreateWallet(name: string, password: string, privateKeyHex: string, organization: string): RequestParams<WalletInfoCreation> {
        return {
            method: "POST",
            url: GetAPIURL("/wallet"),
            json: {
                name: name,
                password: password,
                private_key: privateKeyHex,
                organization: organization,
            },
        };
    }

    public static RenameWallet(id: string, newName: string): RequestParams<void> {
        return {
            method: "POST",
            url: GetAPIURL("/wallet/" + encodeURIComponent(id)),
            json: {
                name: newName,
            },
        };
    }

    public static DeleteWallet(wallet: UserWallet): RequestParams<void> {
        return {
            method: "DELETE",
            url: GetAPIURL("/wallet"),
            headers: {
                'x-wallet-id': wallet.id,
                'x-wallet-password': wallet.password,
            },
        };
    }

    public static ChangePassword(id: string, oldPassword: string, newPassword: string): RequestParams<void> {
        return {
            method: "POST",
            url: GetAPIURL("/wallet/" + encodeURIComponent(id) + "/password"),
            json: {
                password: oldPassword,
                new_password: newPassword,
            },
        };
    }

    public static Export(id: string, password: string): RequestParams<{ private_key: string }> {
        return {
            method: "POST",
            url: GetAPIURL("/wallet/" + encodeURIComponent(id) + "/export"),
            json: {
                password: password,
            },
        };
    }
}
