// Text utils

"use strict"

/**
 * Removes acents and diacritics.
 * @param str   The input string
 * @returns     The normalized string
 */
 export function normalizeString(str: string): string {
    return str.normalize("NFD").replace(/[\u0300-\u036f]/g, "");
}

/**
 * Escapes html reserved characters.
 * @param html      Input HTML text.
 * @returns         The escaped text.
 */
export function escapeHTML(html: string): string {
    return ("" + html).replace(/&/g, "&amp;").replace(/</g, "&lt;")
        .replace(/>/g, "&gt;").replace(/"/g, "&quot;")
        .replace(/'/g, "&apos;").replace(/\//g, "&#x2f;");
}

/**
 * Turns HTML into text (removes all tags)
 * @param html HTML
 * @returns Text
 */
export function htmlToText(html: string): string {
    return (html + "").replace(/<[^>]*>/g, '').trim();
}

/**
 * Escapes single quotes and reverse bars
 * @param raw The raw input text
 * @returns The escaped text.
 */
export function escapeSingleQuotes(raw: string): string {
    return ("" + raw).replace(/\\/g, "\\\\").replace(/'/g, "\\'");
}

/**
 * Escapes double quotes and reverse bars.
 * @param raw The raw input text
 * @returns The escaped text.
 */
export function escapeDoubleQuotes(raw: string): string {
    return ("" + raw).replace(/"/g, "\\\"").replace(/\\/g, "\\\\");
}

/**
 * Validates an email.
 * @param email The email
 * @returns True if the email is valid
 */
export function validateEmail(email: string): boolean {
    return (/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/).test(email);
}

/**
 * Validates a phone number
 * @param phone The phone number
 * @returns True if the phone number is valid
 */
export function validatePhoneNumber(phone: string): boolean {
    return (/^\(?([0-9]{3})\)?[-.\s]?([0-9]{3})[-.\s]?([0-9]+)$/).test(phone);
}
