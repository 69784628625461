
import { defineComponent } from "vue";

import "/node_modules/flag-icons/css/flag-icons.min.css";

import TopBar from "@/components/layout/TopBar.vue";
import LoadingOverlay from "@/components/layout/LoadingOverlay.vue";
import AuthLoadingOverlay from "@/components/layout/AuthLoadingOverlay.vue";
import SnackBar from "@/components/layout/SnackBar.vue";

import LogoutModal from "@/components/modals/LogoutModal.vue";
import AccountSettingsModal from "@/components/modals/AccountSettingsModal.vue";
import NavBarResponsive from "@/components/modals/NavBarResponsive.vue";
import ChangeLanguageModal from "@/components/modals/ChangeLanguageModal.vue";
import ChangeThemeModal from "@/components/modals/ChangeThemeModal.vue";
import ChangeUsernameModal from "@/components/modals/ChangeUsernameModal.vue";
import ChangeEmailModal from "@/components/modals/ChangeEmailModal.vue";
import ChangePasswordModal from "@/components/modals/ChangePasswordModal.vue";
import AccountDeleteModal from "@/components/modals/AccountDeleteModal.vue";
import DevicesModal from "@/components/modals/DevicesModal.vue";
import TwoFactorEnableModal from "@/components/modals/TwoFactorEnableModal.vue";
import TwoFactorDisableModal from "@/components/modals/TwoFactorDisableModal.vue";

import WalletSettingsModal from "@/components/modals/WalletSettingsModal.vue";
import WalletChangeNameModal from "@/components/modals/wallets/WalletChangeNameModal.vue";
import WalletChangePasswordModal from "@/components/modals/wallets/WalletChangePasswordModal.vue";
import WalletExportKeyModal from "@/components/modals/wallets/WalletExportKeyModal.vue";
import SelectWalletModal from "@/components/modals/wallets/SelectWalletModal.vue";
import WalletCreateModal from "@/components/modals/wallets/WalletCreateModal.vue";
import WalletDeleteModal from "@/components/modals/wallets/WalletDeleteModal.vue";
import WalletTransferTokensModal from "@/components/modals/wallets/WalletTransferTokensModal.vue";
import WalletFundsAddModal from "@/components/modals/wallets/WalletFundsAddModal.vue";
import WalletFundsWithdrawModal from "@/components/modals/wallets/WalletFundsWithdrawModal.vue";

import OrganizationSettingsModal from "@/components/modals/organizations/OrganizationSettingsModal.vue";
import SelectOrganizationModal from "@/components/modals/organizations/SelectOrganizationModal.vue";
import OrganizationCreateModal from "@/components/modals/organizations/OrganizationCreateModal.vue";
import OrganizationLeaveModal from "@/components/modals/organizations/OrganizationLeaveModal.vue";

import NotificationsModal from "@/components/modals/NotificationsModal.vue";

import MessageModal from "@/components/modals/MessageModal.vue";

import { AuthController } from "@/control/auth";
import { AppEvents } from "@/control/app-events";
import { AppPreferences } from "@/control/app-preferences";
import { Timeouts } from "@/utils/timeout";

const unauthorizedAllowedRoutes = [
  "login",
  "signup",
  "signup-success",
  "tfa-login",
  "forgot-password",
  "reset-password",
  "verify-email",
];

export default defineComponent({
  components: {
    TopBar,
    LoadingOverlay,
    AuthLoadingOverlay,
    SnackBar,
    LogoutModal,
    AccountSettingsModal,
    ChangeLanguageModal,
    ChangeThemeModal,
    ChangeUsernameModal,
    ChangeEmailModal,
    ChangePasswordModal,
    AccountDeleteModal,
    DevicesModal,
    TwoFactorEnableModal,
    TwoFactorDisableModal,
    MessageModal,
    WalletSettingsModal,
    WalletChangeNameModal,
    WalletChangePasswordModal,
    WalletExportKeyModal,
    SelectWalletModal,
    WalletCreateModal,
    WalletDeleteModal,
    WalletTransferTokensModal,
    WalletFundsAddModal,
    WalletFundsWithdrawModal,
    OrganizationSettingsModal,
    SelectOrganizationModal,
    OrganizationCreateModal,
    OrganizationLeaveModal,
    NotificationsModal,
    NavBarResponsive,
  },
  name: "MainLayout",
  data: function () {
    return {
      sidebarExpanded: true,
      isDarkTheme: false,
      loading: !AuthController.isAuthenticated(),
      loadingAuth: AuthController.Loading,
      isLogin: false,

      displayModalLogout: false,
      displayModalAccountSettings: false,
      displayNavbarResponsive: false,
      displayModalLanguage: false,
      displayModalTheme: false,
      displayModalChangeUsername: false,
      displayModalChangeEmail: false,
      displayModalChangePassword: false,
      displayModalDeleteAccount: false,
      displayModalDevices: false,
      displayModalTwoFactorAuthEnable: false,
      displayModalTwoFactorAuthDisable: false,
      displayModalAddFundsWallet: false,
      displayModalWithdrawFundsWallet: false,

      displayModalMessage: false,
      messageModalTitle: "",
      messageModalMessage: "",

      displayModalWallet: false,
      displayModalSelectWallet: false,
      displayModalCreateWallet: false,
      displayModalRenameWallet: false,
      displayModalChangePasswordWallet: false,
      displayModalExportWallet: false,
      displayModalDeleteWallet: false,
      displayModalBalanceWallet: false,
      displayModalTransferWallet: false,

      displayModalOrganizationSettings: false,
      displayModalSelectOrganization: false,
      displayModalLeaveOrganization: false,
      displayModalCreateOrganization: false,

      displayModalNotifications: false,
    };
  },
  methods: {
    onAuthStatusUpdate: function () {
      this.loading = false;

      this.displayModalLogout = false;
      this.displayModalAccountSettings = false;
      this.displayModalChangeUsername = false;
      this.displayModalChangeEmail = false;
      this.displayModalChangePassword = false;
      this.displayModalDeleteAccount = false;
      this.displayModalDevices = false;
      this.displayModalTwoFactorAuthEnable = false;
      this.displayModalTwoFactorAuthDisable = false;

      this.displayModalWallet = false;
      this.displayModalSelectWallet = false;
      this.displayModalCreateWallet = false;
      this.displayModalRenameWallet = false;
      this.displayModalChangePasswordWallet = false;
      this.displayModalExportWallet = false;
      this.displayModalDeleteWallet = false;
      this.displayWithdrawModal = false;

      this.displayModalOrganizationSettings = false;
      this.displayModalSelectOrganization = false;
      this.displayModalLeaveOrganization = false;
      this.displayModalCreateOrganization = false;

      this.displayModalNotifications = false;

      this.logged = AuthController.isAuthenticated();

      if (AuthController.Loading) {
        return;
      }

      if (
        this.loading &&
        !unauthorizedAllowedRoutes.includes(this.$route ? this.$route.name : "")
      ) {
        AuthController.PageToGo = this.$route ? this.$route.name : "home";
        if (AuthController.isAskingForTwoFactor()) {
          this.$router.push({ name: "tfa-login" });
        } else {
          this.$router.push({ name: "login" });
        }
      }
    },

    closeSideBar: function () {
      this.sidebarExpanded = false;
    },

    onAuthLoadingChanged: function () {
      this.loadingAuth = AuthController.Loading;
    },

    onThemeChanged: function () {
      this.isDarkTheme = AppPreferences.Theme === "dark";
    },

    toggleMenu: function () {
      this.sidebarExpanded = !this.sidebarExpanded;
    },

    skipMainContent: function (event) {
      if (event) {
        event.preventDefault();
      }
      const content: any = document.querySelector(".page-content");
      if (content) {
        content.focus();
      }
    },

    showMessage: function (title: string, msg: string) {
      this.messageModalTitle = title;
      this.messageModalMessage = msg;
      this.displayModalMessage = true;
    },

    checkRoute: function () {
      if(this.$route.name === "login" || this.$route.name === "signup" || this.$route.name === "forgot-password") {
        this.isLogin = true;
        return;
      }
      this.isLogin = false;
    },

  },
  mounted: function () {
    this.$options.onAuthStatusUpdateH = this.onAuthStatusUpdate.bind(this);
    AppEvents.AddEventListener(
      "auth-status-changed",
      this.$options.onAuthStatusUpdateH
    );

    this.$options.onAuthLoadingChangedH = this.onAuthLoadingChanged.bind(this);
    AppEvents.AddEventListener(
      "auth-status-loading",
      this.$options.onAuthLoadingChangedH
    );

    this.$options.onThemeChangedH = this.onThemeChanged.bind(this);
    AppEvents.AddEventListener("theme-changed", this.$options.onThemeChangedH);
    this.onThemeChanged();

    this.$options.showMessageH = this.showMessage.bind(this);
    AppEvents.AddEventListener("msg-modal", this.$options.showMessageH);

    this.onAuthStatusUpdate();

    if (this.$route.query.withdraw_open) {
      Timeouts.Set("withdraw-funds-open", 500, () => {
        AppEvents.Emit("withdraw-funds-wallet-open");
      });
    }
  },
  beforeUnmount: function () {
    AppEvents.RemoveEventListener(
      "auth-status-changed",
      this.$options.onAuthStatusUpdateH
    );
    AppEvents.RemoveEventListener(
      "auth-status-loading",
      this.$options.onAuthLoadingChangedH
    );
    AppEvents.RemoveEventListener(
      "theme-changed",
      this.$options.onThemeChangedH
    );

    AppEvents.RemoveEventListener("msg-modal", this.$options.showMessageH);
  },
  watch: {
    $route: function () {
      this.onAuthStatusUpdate();
      this.checkRoute();
      if (this.$route.query.withdraw_open) {
        AppEvents.Emit("withdraw-funds-wallet-open");
      }
    },
  },
});
