
import { AppEvents } from "@/control/app-events";
import { Request } from "@/utils/request";
import { defineComponent } from "vue";
import { useVModel } from "../../../utils/vmodel";
import { FocusTrap } from "../../../utils/focus-trap";
import { WalletsController } from "@/control/wallets";
import { CurrencyTokenAPI, BalanceResponse } from "@/api/api-currency-token";
import { getBigIntegerQuantity, renderBigIntegerQuantity } from "@/utils/bigint";
import { AuthController } from "@/control/auth";

export default defineComponent({
  name: "WalletTransferTokensModal",
  emits: ["update:display"],
  props: {
    display: Boolean,
  },
  setup(props) {
    return {
      displayStatus: useVModel(props, "display"),
    };
  },
  data: function () {
    return {
      wid: WalletsController.CurrentWalletId,
      walletName: WalletsController.CurrentWalletName,
      walletAddress: WalletsController.CurrentWalletAddress,

      address: "",
      balance: "",
      walletPassword: "",
      symbol: "",
      decimals: 0,
      showPassword: false,

      amount: "",

      busy: false,
      error: "",
    };
  },
  methods: {
    autoFocus: function () {
      const elem = this.$el.querySelector(".auto-focus");
      if (elem) {
        setTimeout(() => {
          elem.focus();
        }, 200);
      }
    },

    open: function () {
      this.displayStatus = true;
    },

    onOpen: function () {
      this.wid = WalletsController.CurrentWalletId;
      this.walletName = WalletsController.CurrentWalletName;
      this.walletAddress = WalletsController.CurrentWalletAddress;
      this.error = "";

      Request.Pending(
        "balance-load",
        CurrencyTokenAPI.GetBalance(WalletsController.CurrentWalletAddress)
      )
        .onSuccess((balance: BalanceResponse) => {
          this.balance = balance.balance;
          this.decimals = balance.decimals;
          this.symbol = balance.symbol;
          this.loading = false;

        })
        .onRequestError((err) => {
          this.busy = false;
          Request.ErrorHandler()
            .add(401, "*", () => {
              AppEvents.Emit("unauthorized");
            })
            .add(400, "INVALID_NAME", () => {
              this.error = this.$t("Invalid name provided");
            })
            .add(400, "*", () => {
              this.error = this.$t("Bad request");
            })
            .add(404, "*", () => {
              this.error = this.$t("Wallet not found");
            })
            .add(500, "*", () => {
              this.error = this.$t("Internal server error");
            })
            .add("*", "*", () => {
              this.error = this.$t("Could not connect to the server");
            })
            .handle(err);
        })
        .onUnexpectedError((err) => {
          this.error = err.message;
          console.error(err);
          this.busy = false;
        });

      this.autoFocus();
    },

    close: function () {
      this.displayStatus = false;
    },

    escapeToClose: function (event) {
      if (event.key === "Escape") {
        this.close();
      }
    },

    stopPropagationEvent: function (e) {
      e.stopPropagation();
    },

    renderWalletAddress: function (a: string) {
      return a.substring(0, 8) + "..." + a.substring(a.length - 8);
    },

    renderAmount: function (amount: string, decimals: number): string {
            return renderBigIntegerQuantity(amount, decimals);
        },

    submit: function (e) {
      if (e) {
        e.preventDefault();
      }
      if (this.busy) {
        return;
      }

      const bigIntAmount = getBigIntegerQuantity(this.amount + "", AuthController.TokenDecimals);

      this.busy = true;
      this.error = "";
      Request.Do(CurrencyTokenAPI.Transfer({ to: this.address, value: bigIntAmount.toString(10)}, { id: WalletsController.CurrentWalletId, password: this.walletPassword }))
        .onSuccess(() => {
          this.busy = false;
          this.close();
          AppEvents.Emit("snack", this.$t("Tokens transferred"));
        })
        .onCancel(() => {
          this.busy = false;
        })
        .onRequestError((err) => {
          this.busy = false;
          Request.ErrorHandler()
            .add(401, "*", () => {
              AppEvents.Emit("unauthorized");
            })
            .add(400, "*", () => {
              this.error = this.$t("Bad request");
            })
            .add(404, "*", () => {
              this.error = this.$t("Wallet not found");
            })
            .add(500, "*", () => {
              this.error = this.$t("Internal server error");
            })
            .add("*", "*", () => {
              this.error = this.$t("Could not connect to the server");
            })
            .handle(err);
        })
        .onUnexpectedError((err) => {
          this.error = err.message;
          console.error(err);
          this.busy = false;
        });
    },
  },
  mounted: function () {
    this.$options.openH = this.open.bind(this);
    AppEvents.AddEventListener("transfer-tokens-wallet-open", this.$options.openH);

    this.$options.focusTrap = new FocusTrap(this.$el, this.close.bind(this));
  },
  beforeUnmount: function () {
    AppEvents.RemoveEventListener("transfer-tokens-wallet-open", this.$options.openH);
    if (this.$options.focusTrap) {
      this.$options.focusTrap.destroy();
    }
  },
  watch: {
    display: function () {
      if (this.display) {
        this.$options.focusTrap.activate();
        this.onOpen();
      } else {
        this.$options.focusTrap.deactivate();
      }
    },
  },
});
