import { AppEvents } from "@/control/app-events";

/**
 * Copy a string and show a message.
 *
 * @param addr - String for copy
 * @param message - Message to show
 *
 * @example
 * 
 * import { copyAddressUtil } from '@/utils/copy-address';
 * 
 *   #  methods: {
 *       copyAddressUtil,
 *   }
 *
 *   -- it is recommended to use this line of html for the icon.
 * 
 *   #html
 *   <a @click="copyAddressUtil('string to copy', $t('message'))">
 *       <i class="fa-regular fa-copy"></i>
 *   </a>
 *
 *
 */
export function copyAddressUtil(addr: string, message: string): void {
  navigator.clipboard.writeText(addr);
  AppEvents.ShowSnackBar(message);
}

/**
 * Format a string and return this format.
 *
 * @param input - String to format
 * @param size - Size of each slice
 *
 * @example
 * 
 * import { truncateHash } from '@/utils/truncate-hash';
 * 
 *   #  methods: {
 *       truncateHash,
 *   }
 *
 *   -- it is recommended to use this line of html for the icon.
 * 
 *   #html
 *   truncateHash(hash, optional size)
 *
 *
 */
export function truncateHash(input: string, size = 4): string {
  if (input.length <= size * 2) {
    return input;
  }
  return `${input.substring(0, size)}...${input.substring(input.length - size)}`;
}
