
import { AppEvents } from "@/control/app-events";
import { Request } from "@/utils/request";
import { defineComponent } from "vue";
import { useVModel } from "../../../utils/vmodel";
import { FocusTrap } from "../../../utils/focus-trap";
import { WalletsController } from "@/control/wallets";
import { PaymentAPI } from "@/api/api-payment";
import { AuthController } from "@/control/auth";
import { getBigIntegerQuantity } from "@/utils/bigint";

export default defineComponent({
  name: "WalletFundsAddModal",
  emits: ["update:display"],
  props: {
    display: Boolean,
  },
  setup(props) {
    return {
      displayStatus: useVModel(props, "display"),
    };
  },
  data: function () {
    return {
      name: "",

      privKey: "",

      address: WalletsController.CurrentWalletAddress,
      walletName: WalletsController.CurrentWalletName,
      addressID: "",
      amount: "",
      walletPassword: "",
      showPassword: false,

      tokenCurrency: AuthController.TokenSymbol,

      busy: false,
      error: "",
    };
  },
  methods: {
    autoFocus: function () {
      const elem = this.$el.querySelector(".auto-focus");
      if (elem) {
        setTimeout(() => {
          elem.focus();
        }, 200);
      }
    },

    open: function () {
      this.displayStatus = true;
    },

    onOpen: function () {
      this.address = WalletsController.CurrentWalletAddress;
      this.walletName = WalletsController.CurrentWalletName;
      this.addressID = WalletsController.CurrentWalletId;
      this.amount = "0";
      this.walletPassword = "";
      this.tokenCurrency = AuthController.TokenSymbol;
      this.autoFocus();
    },

    close: function () {
      this.displayStatus = false;
    },

    escapeToClose: function (event) {
      if (event.key === "Escape") {
        this.close();
      }
    },

    stopPropagationEvent: function (e) {
      e.stopPropagation();
    },

    renderWalletAddress: function (a: string) {
      return a.substring(0, 8) + "..." + a.substring(a.length - 8);
    },

    submit: function (e) {
      if (e) {
        e.preventDefault();
      }
      if (this.busy) {
        return;
      }

      if (!this.amount) {
        this.error = this.$t("You must specify an amount");
        return;
      }

      if (!this.walletPassword) {
        this.error = this.$t("You must specify a password");
        return;
      }

      const amountBigInt = getBigIntegerQuantity(this.amount, AuthController.TokenDecimals);

      this.busy = true;
      this.error = "";

      Request.Do(
        PaymentAPI.AddBalance(
          {
            amount: amountBigInt.toString(10),
            method: "card",
          },
          {
            id: WalletsController.CurrentWalletId,
            password: this.walletPassword,
          }
        )
      )
        .onSuccess((url) => {
          window.location.replace(url.url);
          this.busy = false;
          this.close();
        })
        .onCancel(() => {
          this.busy = false;
        })
        .onRequestError((err) => {
          this.busy = false;
          Request.ErrorHandler()
            .add(401, "*", () => {
              AppEvents.Emit("unauthorized");
            })
            .add(400, "*", () => {
              this.error = this.$t("Bad request");
            })
            .add(400, "WRONG_PASSWORD", () => {
              this.error = this.$t("Incorrect password");
            })
            .add(404, "*", () => {
              this.error = this.$t("Wallet not found");
            })
            .add(500, "*", () => {
              this.error = this.$t("Internal server error");
            })
            .add("*", "*", () => {
              this.error = this.$t("Could not connect to the server");
            })
            .handle(err);
        })
        .onUnexpectedError((err) => {
          this.error = err.message;
          console.error(err);
          this.busy = false;
        });
    },
  },
  mounted: function () {
    this.$options.openH = this.open.bind(this);
    AppEvents.AddEventListener("add-funds-wallet-open", this.$options.openH);

    this.$options.focusTrap = new FocusTrap(this.$el, this.close.bind(this));
  },
  beforeUnmount: function () {
    AppEvents.RemoveEventListener("add-funds-wallet-open", this.$options.openH);
    if (this.$options.focusTrap) {
      this.$options.focusTrap.destroy();
    }
  },
  watch: {
    display: function () {
      if (this.display) {
        this.$options.focusTrap.activate();
        this.onOpen();
      } else {
        this.$options.focusTrap.deactivate();
      }
    },
  },
});
