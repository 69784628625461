import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createBlock as _createBlock, createVNode as _createVNode, normalizeClass as _normalizeClass } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_TopBar = _resolveComponent("TopBar")!
  const _component_LoadingOverlay = _resolveComponent("LoadingOverlay")!
  const _component_AuthLoadingOverlay = _resolveComponent("AuthLoadingOverlay")!
  const _component_router_view = _resolveComponent("router-view")!
  const _component_AccountSettingsModal = _resolveComponent("AccountSettingsModal")!
  const _component_NavBarResponsive = _resolveComponent("NavBarResponsive")!
  const _component_ChangeLanguageModal = _resolveComponent("ChangeLanguageModal")!
  const _component_ChangeThemeModal = _resolveComponent("ChangeThemeModal")!
  const _component_ChangeUsernameModal = _resolveComponent("ChangeUsernameModal")!
  const _component_ChangeEmailModal = _resolveComponent("ChangeEmailModal")!
  const _component_ChangePasswordModal = _resolveComponent("ChangePasswordModal")!
  const _component_AccountDeleteModal = _resolveComponent("AccountDeleteModal")!
  const _component_DevicesModal = _resolveComponent("DevicesModal")!
  const _component_TwoFactorEnableModal = _resolveComponent("TwoFactorEnableModal")!
  const _component_TwoFactorDisableModal = _resolveComponent("TwoFactorDisableModal")!
  const _component_WalletSettingsModal = _resolveComponent("WalletSettingsModal")!
  const _component_WalletChangeNameModal = _resolveComponent("WalletChangeNameModal")!
  const _component_WalletChangePasswordModal = _resolveComponent("WalletChangePasswordModal")!
  const _component_WalletExportKeyModal = _resolveComponent("WalletExportKeyModal")!
  const _component_SelectWalletModal = _resolveComponent("SelectWalletModal")!
  const _component_WalletCreateModal = _resolveComponent("WalletCreateModal")!
  const _component_WalletDeleteModal = _resolveComponent("WalletDeleteModal")!
  const _component_WalletTransferTokensModal = _resolveComponent("WalletTransferTokensModal")!
  const _component_WalletFundsAddModal = _resolveComponent("WalletFundsAddModal")!
  const _component_WalletFundsWithdrawModal = _resolveComponent("WalletFundsWithdrawModal")!
  const _component_OrganizationSettingsModal = _resolveComponent("OrganizationSettingsModal")!
  const _component_SelectOrganizationModal = _resolveComponent("SelectOrganizationModal")!
  const _component_OrganizationCreateModal = _resolveComponent("OrganizationCreateModal")!
  const _component_OrganizationLeaveModal = _resolveComponent("OrganizationLeaveModal")!
  const _component_NotificationsModal = _resolveComponent("NotificationsModal")!
  const _component_LogoutModal = _resolveComponent("LogoutModal")!
  const _component_MessageModal = _resolveComponent("MessageModal")!
  const _component_SnackBar = _resolveComponent("SnackBar")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["main-layout light-theme", {
    'login': _ctx.$route.name === 'login' || _ctx.$route.name === 'signup' || _ctx.$route.name === 'forgot-password'
  }])
  }, [
    (!_ctx.loading)
      ? (_openBlock(), _createElementBlock("a", {
          key: 0,
          href: "#",
          onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.skipMainContent && _ctx.skipMainContent(...args))),
          class: "skip-main-content"
        }, [
          _createElementVNode("span", null, _toDisplayString(_ctx.$t("Skip to main content")), 1)
        ]))
      : _createCommentVNode("", true),
    (!_ctx.loading && !_ctx.isLogin)
      ? (_openBlock(), _createBlock(_component_TopBar, {
          key: 1,
          onToggleMenu: _ctx.toggleMenu
        }, null, 8, ["onToggleMenu"]))
      : _createCommentVNode("", true),
    _createVNode(_component_LoadingOverlay, { display: _ctx.loading }, null, 8, ["display"]),
    _createVNode(_component_AuthLoadingOverlay, { display: _ctx.loadingAuth }, null, 8, ["display"]),
    _createVNode(_component_router_view),
    _createVNode(_component_AccountSettingsModal, {
      display: _ctx.displayModalAccountSettings,
      "onUpdate:display": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.displayModalAccountSettings) = $event))
    }, null, 8, ["display"]),
    _createVNode(_component_NavBarResponsive, {
      display: _ctx.displayNavbarResponsive,
      "onUpdate:display": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.displayNavbarResponsive) = $event))
    }, null, 8, ["display"]),
    _createVNode(_component_ChangeLanguageModal, {
      display: _ctx.displayModalLanguage,
      "onUpdate:display": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.displayModalLanguage) = $event))
    }, null, 8, ["display"]),
    _createVNode(_component_ChangeThemeModal, {
      display: _ctx.displayModalTheme,
      "onUpdate:display": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.displayModalTheme) = $event))
    }, null, 8, ["display"]),
    _createVNode(_component_ChangeUsernameModal, {
      display: _ctx.displayModalChangeUsername,
      "onUpdate:display": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.displayModalChangeUsername) = $event))
    }, null, 8, ["display"]),
    _createVNode(_component_ChangeEmailModal, {
      display: _ctx.displayModalChangeEmail,
      "onUpdate:display": _cache[6] || (_cache[6] = ($event: any) => ((_ctx.displayModalChangeEmail) = $event))
    }, null, 8, ["display"]),
    _createVNode(_component_ChangePasswordModal, {
      display: _ctx.displayModalChangePassword,
      "onUpdate:display": _cache[7] || (_cache[7] = ($event: any) => ((_ctx.displayModalChangePassword) = $event))
    }, null, 8, ["display"]),
    _createVNode(_component_AccountDeleteModal, {
      display: _ctx.displayModalDeleteAccount,
      "onUpdate:display": _cache[8] || (_cache[8] = ($event: any) => ((_ctx.displayModalDeleteAccount) = $event))
    }, null, 8, ["display"]),
    _createVNode(_component_DevicesModal, {
      display: _ctx.displayModalDevices,
      "onUpdate:display": _cache[9] || (_cache[9] = ($event: any) => ((_ctx.displayModalDevices) = $event))
    }, null, 8, ["display"]),
    _createVNode(_component_TwoFactorEnableModal, {
      display: _ctx.displayModalTwoFactorAuthEnable,
      "onUpdate:display": _cache[10] || (_cache[10] = ($event: any) => ((_ctx.displayModalTwoFactorAuthEnable) = $event))
    }, null, 8, ["display"]),
    _createVNode(_component_TwoFactorDisableModal, {
      display: _ctx.displayModalTwoFactorAuthDisable,
      "onUpdate:display": _cache[11] || (_cache[11] = ($event: any) => ((_ctx.displayModalTwoFactorAuthDisable) = $event))
    }, null, 8, ["display"]),
    _createVNode(_component_WalletSettingsModal, {
      display: _ctx.displayModalWallet,
      "onUpdate:display": _cache[12] || (_cache[12] = ($event: any) => ((_ctx.displayModalWallet) = $event))
    }, null, 8, ["display"]),
    _createVNode(_component_WalletChangeNameModal, {
      display: _ctx.displayModalRenameWallet,
      "onUpdate:display": _cache[13] || (_cache[13] = ($event: any) => ((_ctx.displayModalRenameWallet) = $event))
    }, null, 8, ["display"]),
    _createVNode(_component_WalletChangePasswordModal, {
      display: _ctx.displayModalChangePasswordWallet,
      "onUpdate:display": _cache[14] || (_cache[14] = ($event: any) => ((_ctx.displayModalChangePasswordWallet) = $event))
    }, null, 8, ["display"]),
    _createVNode(_component_WalletExportKeyModal, {
      display: _ctx.displayModalExportWallet,
      "onUpdate:display": _cache[15] || (_cache[15] = ($event: any) => ((_ctx.displayModalExportWallet) = $event))
    }, null, 8, ["display"]),
    _createVNode(_component_SelectWalletModal, {
      display: _ctx.displayModalSelectWallet,
      "onUpdate:display": _cache[16] || (_cache[16] = ($event: any) => ((_ctx.displayModalSelectWallet) = $event))
    }, null, 8, ["display"]),
    _createVNode(_component_WalletCreateModal, {
      display: _ctx.displayModalCreateWallet,
      "onUpdate:display": _cache[17] || (_cache[17] = ($event: any) => ((_ctx.displayModalCreateWallet) = $event))
    }, null, 8, ["display"]),
    _createVNode(_component_WalletDeleteModal, {
      display: _ctx.displayModalDeleteWallet,
      "onUpdate:display": _cache[18] || (_cache[18] = ($event: any) => ((_ctx.displayModalDeleteWallet) = $event))
    }, null, 8, ["display"]),
    _createVNode(_component_WalletTransferTokensModal, {
      display: _ctx.displayModalTransferWallet,
      "onUpdate:display": _cache[19] || (_cache[19] = ($event: any) => ((_ctx.displayModalTransferWallet) = $event))
    }, null, 8, ["display"]),
    _createVNode(_component_WalletFundsAddModal, {
      display: _ctx.displayModalAddFundsWallet,
      "onUpdate:display": _cache[20] || (_cache[20] = ($event: any) => ((_ctx.displayModalAddFundsWallet) = $event))
    }, null, 8, ["display"]),
    _createVNode(_component_WalletFundsWithdrawModal, {
      display: _ctx.displayModalWithdrawFundsWallet,
      "onUpdate:display": _cache[21] || (_cache[21] = ($event: any) => ((_ctx.displayModalWithdrawFundsWallet) = $event))
    }, null, 8, ["display"]),
    _createVNode(_component_OrganizationSettingsModal, {
      display: _ctx.displayModalOrganizationSettings,
      "onUpdate:display": _cache[22] || (_cache[22] = ($event: any) => ((_ctx.displayModalOrganizationSettings) = $event))
    }, null, 8, ["display"]),
    _createVNode(_component_SelectOrganizationModal, {
      display: _ctx.displayModalSelectOrganization,
      "onUpdate:display": _cache[23] || (_cache[23] = ($event: any) => ((_ctx.displayModalSelectOrganization) = $event))
    }, null, 8, ["display"]),
    _createVNode(_component_OrganizationCreateModal, {
      display: _ctx.displayModalCreateOrganization,
      "onUpdate:display": _cache[24] || (_cache[24] = ($event: any) => ((_ctx.displayModalCreateOrganization) = $event))
    }, null, 8, ["display"]),
    _createVNode(_component_OrganizationLeaveModal, {
      display: _ctx.displayModalLeaveOrganization,
      "onUpdate:display": _cache[25] || (_cache[25] = ($event: any) => ((_ctx.displayModalLeaveOrganization) = $event))
    }, null, 8, ["display"]),
    _createVNode(_component_NotificationsModal, {
      display: _ctx.displayModalNotifications,
      "onUpdate:display": _cache[26] || (_cache[26] = ($event: any) => ((_ctx.displayModalNotifications) = $event))
    }, null, 8, ["display"]),
    _createVNode(_component_LogoutModal, {
      display: _ctx.displayModalLogout,
      "onUpdate:display": _cache[27] || (_cache[27] = ($event: any) => ((_ctx.displayModalLogout) = $event))
    }, null, 8, ["display"]),
    _createVNode(_component_MessageModal, {
      display: _ctx.displayModalMessage,
      "onUpdate:display": _cache[28] || (_cache[28] = ($event: any) => ((_ctx.displayModalMessage) = $event)),
      title: _ctx.messageModalTitle,
      message: _ctx.messageModalMessage
    }, null, 8, ["display", "title", "message"]),
    _createVNode(_component_SnackBar)
  ], 2))
}