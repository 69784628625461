// Users admin API

import { GetAPIURL, RequestParams } from "@/utils/request";

export interface UserListItem {
    id: string;
    username: string;
    email: string;
    verified: boolean;
    admin: boolean;
    created: number;
}

export interface UserDetails {
    id: string;
    username: string;
    email: string;
    verified: boolean;
    admin: boolean;
    permissions: string[];
    banned: boolean;
    created: number;
}

export interface UserList {
    users: UserListItem[];
    page: number;
    totalPages: number;
    total: number;
}

export class UsersAdminAPI {
    public static ListUsers(page: number, filter: string): RequestParams<UserList> {
        return {
            method: "GET",
            url: GetAPIURL("/admin/users?page" + page + "&filter=" + encodeURIComponent(filter)),
        };
    }

    public static GetUser(uid: string): RequestParams<UserDetails> {
        return {
            method: "GET",
            url: GetAPIURL("/admin/users/" + encodeURIComponent(uid)),
        };
    }

    public static UpdatePermissions(uid: string, permissions: string[]): RequestParams<void> {
        return {
            method: "POST",
            url: GetAPIURL("/admin/users/" + encodeURIComponent(uid) + "/permissions"),
            json: {
                permissions: permissions,
            },
        };
    }

    public static UpdatePermissionsWithNotification(uid: string, permissions: string[], notificationId: string, reason?: string): RequestParams<void> {
        return {
            method: "POST",
            url: GetAPIURL("/admin/users/" + encodeURIComponent(uid) + "/permissions/" + encodeURIComponent(notificationId)),
            json: {
                permissions: permissions,
                reason: reason
            },
        };
    }

    public static Ban(uid: string): RequestParams<void> {
        return {
            method: "POST",
            url: GetAPIURL("/admin/users/" + encodeURIComponent(uid) + "/ban"),
        };
    }

    public static Unban(uid: string): RequestParams<void> {
        return {
            method: "POST",
            url: GetAPIURL("/admin/users/" + encodeURIComponent(uid) + "/unban"),
        };
    }

    public static GrantAdmin(uid: string): RequestParams<void> {
        return {
            method: "POST",
            url: GetAPIURL("/admin/users/" + encodeURIComponent(uid) + "/grant"),
        };
    }

    public static RevokeAdmin(uid: string): RequestParams<void> {
        return {
            method: "POST",
            url: GetAPIURL("/admin/users/" + encodeURIComponent(uid) + "/revoke"),
        };
    }
}
