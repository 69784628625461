
import { AccountAPI } from "@/api/api-account";
import { AppEvents } from "@/control/app-events";
import { AuthController } from "@/control/auth";
import { Request } from "@/utils/request";
import { defineComponent, nextTick } from "vue";
import { useVModel } from "../../utils/vmodel";
import { FocusTrap } from "../../utils/focus-trap";

export default defineComponent({
  name: "AccountDeleteModal",
  emits: ["update:display"],
  props: {
    display: Boolean,
  },
  setup(props) {
    return {
      displayStatus: useVModel(props, "display"),
    };
  },
  data: function () {
    return {
      password: "",

      confirmation: "",

      tfa: AuthController.RequiresTwoFactorAuthentication,
      token: "",

      busy: false,
      error: "",
    };
  },
  methods: {
    autoFocus: function () {
      const elem = this.$el.querySelector(".auto-focus");
      if (elem) {
        setTimeout(() => {
          elem.focus();
        }, 200);
      }
    },

    open: function () {
      this.displayStatus = true;
      this.password = "";
      this.confirmation = "";
      this.tfa = AuthController.RequiresTwoFactorAuthentication;
      this.error = "";
      this.autoFocus();
    },

    close: function () {
      this.displayStatus = false;
    },

    escapeToClose: function (event) {
      if (event.key === "Escape") {
        this.close();
      }
    },

    stopPropagationEvent: function (e) {
      e.stopPropagation();
    },

    submit: function (e) {
      if (e) {
        e.preventDefault();
      }
      if (this.busy) {
        return;
      }

      this.busy = true;
      this.error = "";
      Request.Do(AccountAPI.DeleteAccount(this.password, this.token))
        .onSuccess(() => {
          this.busy = false;
          this.close();
          AuthController.CheckAuthStatus();
          AppEvents.Emit("snack", this.$t("Account deleted"));
        })
        .onCancel(() => {
          this.busy = false;
        })
        .onRequestError((err) => {
          this.busy = false;
          Request.ErrorHandler()
            .add(401, "*", () => {
              AppEvents.Emit("unauthorized");
            })
            .add(400, "WRONG_PASSWORD", () => {
              this.error = this.$t("Wrong password");
            })
            .add(400, "INVALID_TFA_CODE", () => {
              this.error = this.$t("Invalid one-time password");
            })
            .add(400, "*", () => {
              this.error = this.$t("Bad request");
            })
            .add(500, "*", () => {
              this.error = this.$t("Internal server error");
            })
            .add("*", "*", () => {
              this.error = this.$t("Could not connect to the server");
            })
            .handle(err);
        })
        .onUnexpectedError((err) => {
          this.error = err.message;
          console.error(err);
          this.busy = false;
        });
    },
  },
  mounted: function () {
    this.$options.openH = this.open.bind(this);
    AppEvents.AddEventListener("delete-account-open", this.$options.openH);

    this.$options.focusTrap = new FocusTrap(this.$el, this.close.bind(this));
  },
  beforeUnmount: function () {
    AppEvents.RemoveEventListener("delete-account-open", this.$options.openH);
    this.$options.focusTrap.destroy();
  },
  watch: {
    display: function () {
      if (this.display) {
        this.$options.focusTrap.activate();
        nextTick(() => {
          this.$el.focus();
        });
      } else {
        this.$options.focusTrap.deactivate();
      }
    },
  },
});
