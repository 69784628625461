
import { AppEvents } from "@/control/app-events";
import { Request } from "@/utils/request";
import { defineComponent } from "vue";
import { useVModel } from "../../../utils/vmodel";
import { FocusTrap } from "../../../utils/focus-trap";
import { WalletAPI } from "@/api/api-wallet";
import { WalletsController } from "@/control/wallets";

export default defineComponent({
  name: "WalletChangePasswordModal",
  emits: ["update:display"],
  props: {
    display: Boolean,
  },
  setup(props) {
    return {
      displayStatus: useVModel(props, "display"),
    };
  },
  data: function () {
    return {
      wid: WalletsController.CurrentWalletId,

      oldPassword: "",

      password: "",
      password2: "",
      showPassword: false,
      showPasswordOld: false,
      showPassword2: false,

      busy: false,
      error: "",
    };
  },
  methods: {
    autoFocus: function () {
      const elem = this.$el.querySelector(".auto-focus");
      if (elem) {
        setTimeout(() => {
          elem.focus();
        }, 200);
      }
    },

    open: function () {
      this.displayStatus = true;
    },

    onOpen: function () {
      this.wid = WalletsController.CurrentWalletId;
      this.oldPassword = "";
      this.password = "";
      this.password2 = "";
      this.error = "";
      this.autoFocus();
    },

    close: function () {
      this.displayStatus = false;
    },

    escapeToClose: function (event) {
      if (event.key === "Escape") {
        this.close();
      }
    },

    stopPropagationEvent: function (e) {
      e.stopPropagation();
    },

    submit: function (e) {
      if (e) {
        e.preventDefault();
      }
      if (this.busy) {
        return;
      }

      if (!this.password) {
        this.error = this.$t("You must specify a password");
        return;
      }

      if (this.password !== this.password2) {
        this.error = this.$t("The passwords do not match");
        return;
      }

      this.busy = true;
      this.error = "";
      Request.Do(WalletAPI.ChangePassword(this.wid, this.oldPassword, this.password))
        .onSuccess(() => {
          this.busy = false;
          this.close();
          AppEvents.Emit("snack", this.$t("Password successfully changed"));
        })
        .onCancel(() => {
          this.busy = false;
        })
        .onRequestError((err) => {
          this.busy = false;
          Request.ErrorHandler()
            .add(401, "*", () => {
              AppEvents.Emit("unauthorized");
            })
            .add(400, "WRONG_PASSWORD", () => {
              this.error = this.$t("Wrong password");
            })
            .add(400, "WEAK_PASSWORD", () => {
              this.error = this.$t("The provided password is too short");
            })
            .add(400, "*", () => {
              this.error = this.$t("Bad request");
            })
            .add(403, "*", () => {
              this.error = this.$t("Access denied");
            })
            .add(404, "*", () => {
              this.error = this.$t("Wallet not found");
            })
            .add(500, "*", () => {
              this.error = this.$t("Internal server error");
            })
            .add("*", "*", () => {
              this.error = this.$t("Could not connect to the server");
            })
            .handle(err);
        })
        .onUnexpectedError((err) => {
          this.error = err.message;
          console.error(err);
          this.busy = false;
        });
    },
  },
  mounted: function () {
    this.$options.openH = this.open.bind(this);
    AppEvents.AddEventListener("password-wallet-open", this.$options.openH);

    this.$options.focusTrap = new FocusTrap(this.$el, this.close.bind(this));
  },
  beforeUnmount: function () {
    AppEvents.RemoveEventListener("password-wallet-open", this.$options.openH);
    if (this.$options.focusTrap) {
      this.$options.focusTrap.destroy();
    }
  },
  watch: {
    display: function () {
      if (this.display) {
        this.$options.focusTrap.activate();
        this.onOpen();
      } else {
        this.$options.focusTrap.deactivate();
      }
    },
  },
});
