import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = ["aria-hidden"]
const _hoisted_2 = { class: "modal-body no-padding" }
const _hoisted_3 = { class: "settings-list" }
const _hoisted_4 = { class: "top-bar-link" }
const _hoisted_5 = { class: "" }
const _hoisted_6 = { class: "top-bar-link" }
const _hoisted_7 = { class: "" }
const _hoisted_8 = { class: "top-bar-link" }
const _hoisted_9 = { class: "" }
const _hoisted_10 = { class: "top-bar-link" }
const _hoisted_11 = { class: "" }
const _hoisted_12 = { class: "top-bar-link" }
const _hoisted_13 = { class: "" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["modal-container modal-container-corner no-transition modal-nav", { hidden: !_ctx.display }]),
    tabindex: "-1",
    role: "dialog",
    "aria-hidden": !_ctx.display,
    onClick: _cache[2] || (_cache[2] = 
//@ts-ignore
(...args) => (_ctx.close && _ctx.close(...args))),
    onKeydown: _cache[3] || (_cache[3] = 
//@ts-ignore
(...args) => (_ctx.escapeToClose && _ctx.escapeToClose(...args)))
  }, [
    _createElementVNode("div", {
      class: "nav-responsive",
      onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.stopPropagationEvent && _ctx.stopPropagationEvent(...args)))
    }, [
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("div", _hoisted_3, [
          _createElementVNode("ul", {
            onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.close && _ctx.close(...args)))
          }, [
            _createElementVNode("li", _hoisted_4, [
              _createVNode(_component_router_link, {
                class: "menu-item",
                title: _ctx.$t('Home'),
                to: { name: 'home' }
              }, {
                default: _withCtx(() => [
                  _createElementVNode("div", _hoisted_5, _toDisplayString(_ctx.$t("Home")), 1)
                ]),
                _: 1
              }, 8, ["title"])
            ]),
            _createElementVNode("li", _hoisted_6, [
              _createVNode(_component_router_link, {
                class: "menu-item",
                title: _ctx.$t('Buy Carbon Credits'),
                to: { name: 'central-offset-registry' }
              }, {
                default: _withCtx(() => [
                  _createElementVNode("div", _hoisted_7, _toDisplayString(_ctx.$t("Buy Carbon Credits")), 1)
                ]),
                _: 1
              }, 8, ["title"])
            ]),
            _createElementVNode("li", _hoisted_8, [
              _createVNode(_component_router_link, {
                class: "menu-item",
                title: _ctx.$t('Projects'),
                to: { name: 'projects' }
              }, {
                default: _withCtx(() => [
                  _createElementVNode("div", _hoisted_9, _toDisplayString(_ctx.$t("Projects")), 1)
                ]),
                _: 1
              }, 8, ["title"])
            ]),
            _createElementVNode("li", _hoisted_10, [
              _createVNode(_component_router_link, {
                class: "menu-item",
                title: _ctx.$t('Methodologies'),
                to: { name: 'methodologies' }
              }, {
                default: _withCtx(() => [
                  _createElementVNode("div", _hoisted_11, _toDisplayString(_ctx.$t("Methodologies")), 1)
                ]),
                _: 1
              }, 8, ["title"])
            ]),
            _createElementVNode("li", _hoisted_12, [
              _createVNode(_component_router_link, {
                class: "menu-item",
                title: _ctx.$t('co2 calculator'),
                to: { name: 'co2-calculator' }
              }, {
                default: _withCtx(() => [
                  _createElementVNode("div", _hoisted_13, _toDisplayString(_ctx.$t("CO2 CALCULATOR")), 1)
                ]),
                _: 1
              }, 8, ["title"])
            ])
          ])
        ])
      ])
    ])
  ], 42, _hoisted_1))
}