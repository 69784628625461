
import { defineComponent, nextTick } from "vue";
import { useVModel } from "../../utils/vmodel";
import { FocusTrap } from "../../utils/focus-trap";
import { AppEvents } from "@/control/app-events";
import { copyAddressUtil } from "@/utils/copy-address";


export default defineComponent({
    name: "ReminderCreateWalletModal",
    emits: ["update:display", "update:close"],
    props: {
        display: Boolean,
        privKey: String,
    },
    setup(props) {
        return {
            displayStatus: useVModel(props, "display"),
        };
    },
    data: function () {
            return {
                showKey: false,
            };
        },
    methods: {
        close: function () {
            this.$emit("update:close");
            this.displayStatus = false;
        },

        continueClose: function () {
            this.close();
            AppEvents.Emit("wallet-settings-open");
        },

        copyPrivateKey: function (key: string, message: string) {
            copyAddressUtil(key, message);
        },

        showPrivateKey: function () {
            this.showKey = !this.showKey;
        },
    },
    mounted: function () {
        this.$options.focusTrap = new FocusTrap(this.$el, this.close.bind(this));
    },
    beforeUnmount: function () {
        if (this.$options.focusTrap) {
            this.$options.focusTrap.destroy();
        }
    },
    watch: {
        display: function () {
            if (this.display) {
                this.showKey = false;
                this.$options.focusTrap.activate();
                nextTick(() => {
                    this.$el.focus();
                });
            } else {
                this.$options.focusTrap.deactivate();
            }
        },
    },
});
