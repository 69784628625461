
import { AppEvents } from "@/control/app-events";
import { defineComponent, nextTick } from "vue";
import { useVModel } from "@/utils/vmodel";
import { FocusTrap } from "@/utils/focus-trap";
import { AuthController } from "@/control/auth";

export default defineComponent({
  name: "SelectOrganizationModal",
  emits: ["update:display"],
  props: {
    display: Boolean,
  },
  setup(props) {
    return {
      displayStatus: useVModel(props, "display"),
    };
  },
  data: function () {
    return {
      current: AuthController.SelectedOrganization,
      organizations: AuthController.Organizations.map(o => {
        return {
          id: o.id,
          name: o.name,
        };
      }),
    };
  },
  methods: {
    open: function () {
      this.displayStatus = true;
    },

    close: function () {
      this.displayStatus = false;
    },

    escapeToClose: function (event) {
      if (event.key === "Escape") {
        this.close();
      }
    },

    stopPropagationEvent: function (e) {
      e.stopPropagation();
    },

    clickOnEnter: function (event) {
      if (event.key === "Enter") {
        event.preventDefault();
        event.stopPropagation();
        event.target.click();
      }
    },

    goBack: function () {
      this.close();
      AppEvents.Emit("organization-settings-open");
    },

    selectOrganization: function (o: string) {
      AuthController.SelectOrganization(o);
      this.goBack();
    },

    onAuthChanged: function () {
      this.current = AuthController.SelectedOrganization;
      this.organizations = AuthController.Organizations.map(o => {
        return {
          id: o.id,
          name: o.name,
        };
      });
    },
  },
  mounted: function () {
    this.$options.openH = this.open.bind(this);
    AppEvents.AddEventListener("organization-select-open", this.$options.openH);

    this.$options.onAuthChangedH = this.onAuthChanged.bind(this);
    AppEvents.AddEventListener(
      "auth-status-changed",
      this.$options.onAuthChangedH
    );

    this.$options.focusTrap = new FocusTrap(this.$el, this.close.bind(this));
  },
  beforeUnmount: function () {
    AppEvents.RemoveEventListener("organization-select-open", this.$options.openH);
    AppEvents.RemoveEventListener(
      "auth-status-changed",
      this.$options.onAuthChangedH
    );
    this.$options.focusTrap.destroy();
  },
  watch: {
    display: function () {
      if (this.display) {
        this.$options.focusTrap.activate();
        nextTick(() => {
          this.$el.focus();
        });
      } else {
        this.$options.focusTrap.deactivate();
      }
    },
  },
});
