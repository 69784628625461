// Wallets controller

import { WalletAPI, WalletInfo } from "@/api/api-wallet";
import { Request } from "@/utils/request";
import { Timeouts } from "@/utils/timeout";
import { AppEvents } from "./app-events";
import { AppPreferences } from "./app-preferences";
import { AuthController } from "./auth";

export class WalletsController {
    public static Wallets: WalletInfo[] = [];

    public static GetWallets(): WalletInfo[] {
        return WalletsController.Wallets.map(w => {
            return {
                id: w.id,
                name: w.name,
                address: w.address,
                organization: w.organization,
            };
        })
    }

    public static CurrentWalletId = "";
    public static CurrentWalletAddress = "";
    public static CurrentWalletName = "";
    public static CurrentWalletOrganization = "";

    public static Initialize() {
        WalletsController.Load();
        AppEvents.AddEventListener("auth-status-changed", WalletsController.Load);
    }

    public static Load() {
        Timeouts.Abort("wallets-controller-load-list");
        Request.Abort("wallets-controller-load-list");

        if (!AuthController.isAuthenticated()) {
            WalletsController.CurrentWalletId = "";
            WalletsController.CurrentWalletAddress = "";
            WalletsController.CurrentWalletName = "";
            WalletsController.CurrentWalletOrganization = "";
            AppEvents.Emit("current-wallet-changed", WalletsController.CurrentWalletId, WalletsController.CurrentWalletAddress, WalletsController.CurrentWalletName);
            return; // Not logged in
        }

        Request.Pending("wallets-controller-load-list", WalletAPI.ListWallets())
            .onSuccess((result: WalletInfo[]) => {
                WalletsController.Wallets = result.slice();

                AppEvents.Emit("wallet-list-changed");

                const prefWalletId = AppPreferences.GetDefaultWallet(AuthController.UID);
                const prefWallet = result.filter(w => {
                    return w.id === prefWalletId;
                });

                if (prefWallet.length > 0) {
                    WalletsController.CurrentWalletId = prefWallet[0].id;
                    WalletsController.CurrentWalletAddress = prefWallet[0].address;
                    WalletsController.CurrentWalletName = prefWallet[0].name;
                    WalletsController.CurrentWalletOrganization = prefWallet[0].organization;
                } else if (result.length > 0) {
                    WalletsController.CurrentWalletId = result[0].id;
                    WalletsController.CurrentWalletAddress = result[0].address;
                    WalletsController.CurrentWalletName = result[0].name;
                    WalletsController.CurrentWalletOrganization = result[0].organization;
                } else {
                    WalletsController.CurrentWalletId = "";
                    WalletsController.CurrentWalletAddress = "";
                    WalletsController.CurrentWalletName = "";
                    WalletsController.CurrentWalletOrganization = "";
                }
                AppEvents.Emit("current-wallet-changed", WalletsController.CurrentWalletId, WalletsController.CurrentWalletAddress, WalletsController.CurrentWalletName);
            })
            .onRequestError((err) => {
                Request.ErrorHandler()
                    .add(401, "*", () => {
                        AppEvents.Emit("unauthorized", false);
                    })
                    .add("*", "*", () => {
                        // Retry
                        Timeouts.Set("wallets-controller-load-list", 1500, WalletsController.Load);
                    })
                    .handle(err);
            })
            .onUnexpectedError((err) => {
                console.error(err);
                // Retry
                Timeouts.Set("wallets-controller-load-list", 1500, WalletsController.Load);
            });
    }

    public static SetDefaultWallet(wallet: WalletInfo) {
        AppPreferences.SetDefaultWallet(AuthController.UID, wallet.id);
        WalletsController.CurrentWalletId = wallet.id;
        WalletsController.CurrentWalletAddress = wallet.address;
        WalletsController.CurrentWalletName = wallet.name;
        WalletsController.CurrentWalletOrganization = wallet.organization;
        AppEvents.Emit("current-wallet-changed", WalletsController.CurrentWalletId, WalletsController.CurrentWalletAddress, WalletsController.CurrentWalletName);
    }
}