
import { Options, Vue } from "vue-class-component";

import MainLayout from "@/components/layout/MainLayout.vue";

@Options({
  components: {
    MainLayout,
  },
  data: function () {
    return {};
  },
  methods: {},
  mounted: function () {},
  beforeUnmount: function () {},
})
export default class App extends Vue {}
