// Offset registry API

import { GenerateURIQuery, GetAPIURL, RequestParams } from "@/utils/request";
import { UserWallet } from "./api-operator-projects";

export interface RegistryAllowedScheme {
    address: string;
    name: string;
    date: number;
}

export interface Methodology {
    id: string;
    date: number;
    modifyDate: number;
    name: string;
    document: string;
}

export interface RegistryContractData {
    address: string;
    allowedSchemes: RegistryAllowedScheme[];
    tokenCount: number;
    root: string;
    admins: string[];
}

export interface RegistryTransaction {
    id: string;
    block: string;
    hash: string;
    date: number;
    from: string;
    to: string;
    token: string;
}

export interface CreditReference {
    id: string;
    name: string;
}

export interface ProjectCreditReference {
    id: string;
    name: string;
    country: string;
    audited: boolean;
}

export type CreditStatus = "Expected" | "Certified" | "Retired";

export interface CreditItem {
    id: string;
    status: CreditStatus;
    deListed: boolean;
    date: number;
    scheme: ProjectCreditReference;
    project: CreditReference;
    owner: string;
    onSale: boolean;
    amount: string;
    amountUnits: string;
    amountDecimals: number;
    price: string;
    sold: boolean;
}

export interface CreditData {
    id: string;
    status: CreditStatus;
    date: number;
    scheme: CreditReference;
    project: ProjectCreditReference;
    owner: string;
    approved: string;
    onSale: boolean;
    amount: string;
    amountUnits: string;
    amountDecimals: number;
    price: string;
    deListed: boolean;
    deListReason: string;
    sold: boolean;
    metadata: string[];
}

export class OffsetRegistryAPI {
    public static GetMetadata(): RequestParams<RegistryContractData> {
        return {
            method: "GET",
            url: GetAPIURL("/registry"),
        };
    }

    public static GetTransactions(options: { to_date?: number, limit?: number, wallet?: string, token?: string }): RequestParams<RegistryTransaction[]> {
        return {
            method: "GET",
            url: GetAPIURL("/registry/transactions" + GenerateURIQuery(options)),
        };
    }

    public static GetCredits(options: { to_date?: number, limit?: number, project?: string, scheme?: string, status?: string, owner?: string, on_sale?: string, sold?: boolean }): RequestParams<CreditItem[]> {
        return {
            method: "GET",
            url: GetAPIURL("/registry/credits" + GenerateURIQuery(options)),
        };
    }

    public static GetCredit(id: string): RequestParams<CreditData> {
        return {
            method: "GET",
            url: GetAPIURL("/registry/credits/" + encodeURIComponent(id)),
        };
    }

    public static CreateCredit(options: { project: string, scheme: string, amount: string, amountUnits: string, amountDecimals: number }, wallet: UserWallet): RequestParams<{id: string}> {
        return {
            method: "POST",
            url: GetAPIURL("/registry/credits"),
            json: options,
            headers: {
                'x-wallet-id': wallet.id,
                'x-wallet-password': wallet.password,
            },
        };
    }

    public static AddMetadata(id: string, options: { entry: string }, wallet: UserWallet): RequestParams<void> {
        return {
            method: "POST",
            url: GetAPIURL("/registry/credits/" + encodeURIComponent(id) + "/metadata/add"),
            json: options,
            headers: {
                'x-wallet-id': wallet.id,
                'x-wallet-password': wallet.password,
            },
        };
    }

    public static SetMetadata(id: string, options: { index: number, entry: string }, wallet: UserWallet): RequestParams<void> {
        return {
            method: "POST",
            url: GetAPIURL("/registry/credits/" + encodeURIComponent(id) + "/metadata/set"),
            json: options,
            headers: {
                'x-wallet-id': wallet.id,
                'x-wallet-password': wallet.password,
            },
        };
    }

    public static ClearMetadata(id: string, wallet: UserWallet): RequestParams<void> {
        return {
            method: "POST",
            url: GetAPIURL("/registry/credits/" + encodeURIComponent(id) + "/metadata/clear"),
            headers: {
                'x-wallet-id': wallet.id,
                'x-wallet-password': wallet.password,
            },
        };
    }

    public static ChangeMetadata(id: string, options: { metadata: string[] }, wallet: UserWallet): RequestParams<void> {
        return {
            method: "POST",
            url: GetAPIURL("/registry/credits/" + encodeURIComponent(id) + "/metadata/change"),
            json: options,
            headers: {
                'x-wallet-id': wallet.id,
                'x-wallet-password': wallet.password,
            },
        };
    }

    public static CertifyCredit(id: string, options: { certificationBody: string }, wallet: UserWallet): RequestParams<void> {
        return {
            method: "POST",
            url: GetAPIURL("/registry/credits/" + encodeURIComponent(id) + "/certify"),
            json: options,
            headers: {
                'x-wallet-id': wallet.id,
                'x-wallet-password': wallet.password,
            },
        };
    }

    public static NotCertifyCredit(id: string, options: { certificationBody: string, reason: string }, wallet: UserWallet): RequestParams<void> {
        return {
            method: "POST",
            url: GetAPIURL("/registry/credits/" + encodeURIComponent(id) + "/not_certify"),
            json: options,
            headers: {
                'x-wallet-id': wallet.id,
                'x-wallet-password': wallet.password,
            },
        };
    }

    public static ListCredit(id: string, options: { price: string }, wallet: UserWallet): RequestParams<void> {
        return {
            method: "POST",
            url: GetAPIURL("/registry/credits/" + encodeURIComponent(id) + "/list"),
            json: options,
            headers: {
                'x-wallet-id': wallet.id,
                'x-wallet-password': wallet.password,
            },
        };
    }

    public static DeListCredit(id: string, options: { reason: string }, wallet: UserWallet): RequestParams<void> {
        return {
            method: "POST",
            url: GetAPIURL("/registry/credits/" + encodeURIComponent(id) + "/delist"),
            json: options,
            headers: {
                'x-wallet-id': wallet.id,
                'x-wallet-password': wallet.password,
            },
        };
    }

    public static SetApprovedCredit(id: string, options: { address: string }, wallet: UserWallet): RequestParams<void> {
        return {
            method: "POST",
            url: GetAPIURL("/registry/credits/" + encodeURIComponent(id) + "/approved"),
            json: options,
            headers: {
                'x-wallet-id': wallet.id,
                'x-wallet-password': wallet.password,
            },
        };
    }

    public static TransferCredit(id: string, options: { address: string }, wallet: UserWallet): RequestParams<void> {
        return {
            method: "POST",
            url: GetAPIURL("/registry/credits/" + encodeURIComponent(id) + "/transfer"),
            json: options,
            headers: {
                'x-wallet-id': wallet.id,
                'x-wallet-password': wallet.password,
            },
        };
    }

    public static BuyCredit(id: string, options: { price: string }, wallet: UserWallet): RequestParams<void> {
        return {
            method: "POST",
            url: GetAPIURL("/registry/credits/" + encodeURIComponent(id) + "/buy"),
            json: options,
            headers: {
                'x-wallet-id': wallet.id,
                'x-wallet-password': wallet.password,
            },
        };
    }

    public static RetireCredit(id: string, wallet: UserWallet): RequestParams<void> {
        return {
            method: "POST",
            url: GetAPIURL("/registry/credits/" + encodeURIComponent(id) + "/retire"),
            headers: {
                'x-wallet-id': wallet.id,
                'x-wallet-password': wallet.password,
            },
        };
    }

    public static RetireAllCredit(ids: Array<string>, wallet: UserWallet): RequestParams<void> {
        return {
            method: "POST",
            url: GetAPIURL("/registry/credits_all/retire"),
            headers: {
                'x-wallet-id': wallet.id,
                'x-wallet-password': wallet.password,
            },
            json: {ids: ids}
        };
    }

    public static GetSchemes(mode: boolean): RequestParams<Methodology[]> {
        return {
            method: "GET",
            url: GetAPIURL("/registry/schemes/" + encodeURIComponent(mode ? "allowed" : "not-allowed")),
        };
    }

    public static AddAllowedScheme(options: { address: string }, wallet: UserWallet): RequestParams<void> {
        return {
            method: "POST",
            url: GetAPIURL("/registry/schemes/add"),
            json: options,
            headers: {
                'x-wallet-id': wallet.id,
                'x-wallet-password': wallet.password,
            },
        };
    }

    public static RemoveAllowedScheme(options: { address: string }, wallet: UserWallet): RequestParams<void> {
        return {
            method: "POST",
            url: GetAPIURL("/registry/schemes/remove"),
            json: options,
            headers: {
                'x-wallet-id': wallet.id,
                'x-wallet-password': wallet.password,
            },
        };
    }

    public static TransferRoot(options: { address: string }, wallet: UserWallet): RequestParams<void> {
        return {
            method: "POST",
            url: GetAPIURL("/registry/root"),
            json: options,
            headers: {
                'x-wallet-id': wallet.id,
                'x-wallet-password': wallet.password,
            },
        };
    }

    public static GrantAdmin(options: { address: string }, wallet: UserWallet): RequestParams<void> {
        return {
            method: "POST",
            url: GetAPIURL("/registry/admin/grant"),
            json: options,
            headers: {
                'x-wallet-id': wallet.id,
                'x-wallet-password': wallet.password,
            },
        };
    }

    public static RevokeAdmin(options: { address: string }, wallet: UserWallet): RequestParams<void> {
        return {
            method: "POST",
            url: GetAPIURL("/registry/admin/revoke"),
            json: options,
            headers: {
                'x-wallet-id': wallet.id,
                'x-wallet-password': wallet.password,
            },
        };
    }
}