// Vue entry point

"use strict";

import VueGoogleMaps from '@fawmi/vue-google-maps';
import { VueReCaptcha } from 'vue-recaptcha-v3';
import { AppPreferences } from "./control/app-preferences";
import { makeApplicationRouter } from './routes';
import { createApp } from 'vue'
import { i18n } from './i18n'
import App from './App.vue'
import { AuthController } from './control/auth';
import { WalletsController } from './control/wallets';
import { NotificationsController } from './control/notifications';

// Initialize stuff

AppPreferences.LoadPreferences();
AuthController.Initialize();
WalletsController.Initialize();
NotificationsController.Initialize();

// Global event handlers

document.addEventListener("drop", function (e) {
    e.preventDefault();
    e.stopPropagation();
});

document.addEventListener("dragover", function (e) {
    e.preventDefault();
    e.stopPropagation();
});

// Setup App

const app = createApp(App);

app.use(i18n) // Multi-language
app.use(makeApplicationRouter()); // Router

if (process.env.VUE_APP_RECAPTCHA_SITE_ID) {
    app.use(VueReCaptcha, { siteKey: process.env.VUE_APP_RECAPTCHA_SITE_ID, loaderOptions: { autoHideBadge: true } }); // Recaptcha
}

if (process.env.VUE_APP_MAPS_LIBRARY === "GOOGLE") {
    app.use(VueGoogleMaps, { load: { key: process.env.VUE_APP_GOOGLE_MAPS_KEY, libraries: "places,visualization", v: "weekly" } });
}

app.mount('#app');
