
import { defineComponent, nextTick } from "vue";
import { useVModel } from "../../utils/vmodel";

export default defineComponent({
  name: "ProgressModal",
  emits: ["update:display"],
  props: {
    display: Boolean,
    title: String,
    message: String,
  },
  setup(props) {
    return {
      displayStatus: useVModel(props, "display"),
    };
  },
  methods: {
    stopPropagationEvent: function (e) {
      e.stopPropagation();
    },
  },
  mounted: function () {
  },
  beforeUnmount: function () {
  },
  watch: {
    display: function () {
      if (this.display) {
        nextTick(() => {
          this.$el.focus();
        });
      }
    },
  },
});
