
import { AppEvents } from "@/control/app-events";
import { AppPreferences } from "@/control/app-preferences";
import { AuthController } from "@/control/auth";
import { NotificationsController } from "@/control/notifications";
import { defineComponent } from "vue";

export default defineComponent({
  components: {},
  name: "TopBar",
  emits: ['toggle-menu'],
  data: function () {
    return {
      page: '',
      isDarkTheme: false,
      logged: AuthController.isAuthenticated(),

      canProjects: AuthController.CanRegisterProjects(),
      canBodies: AuthController.CanRegisterCertificationBodies(),
      canSchemes: AuthController.CanRegisterCertificationSchemes(),

      notificationsUnread: NotificationsController.UnreadCount,

      windowWidth: window.innerWidth,
    };
  },
  methods: {
    openUserSettings: function () {
      AppEvents.Emit("account-settings-open");
    },

    openWalletSettings: function () {
      AppEvents.Emit("wallet-settings-open");
    },

    openSelectLanguage: function () {
      AppEvents.Emit("select-language-open");
    },

    openNavBar: function () {
      AppEvents.Emit("navbar-responsive-open");
    },

    openNotifications: function () {
      AppEvents.Emit("notifications-open");
    },

    login: function () {
      this.$router.push("/login");
    },

    logout: function () {
      AppEvents.Emit("logout-open");
    },

    clickMenu: function () {
      this.$emit("toggle-menu");
    },

    updatePage: function () {
      this.page = this.$route ? (this.$route.name) : '';
    },

    onAuthStatusUpdate: function () {
      this.isAdmin = AuthController.GlobalAdmin;

      this.canProjects = AuthController.CanRegisterProjects();
      this.canBodies = AuthController.CanRegisterCertificationBodies();
      this.canSchemes = AuthController.CanRegisterCertificationSchemes();

      this.logged = AuthController.isAuthenticated();
    },

    onThemeChanged: function () {
      this.isDarkTheme = AppPreferences.Theme === "dark";
    },

    onNotificationsChanged: function () {
      this.notificationsUnread = NotificationsController.UnreadCount;
    },

    handleResize() {
      this.windowWidth = window.innerWidth;
    }
  },
  mounted: function () {
    this.$options.onThemeChangedH = this.onThemeChanged.bind(this);
    AppEvents.AddEventListener("theme-changed", this.$options.onThemeChangedH);
    this.onThemeChanged();

    this.$options.onAuthStatusUpdateH = this.onAuthStatusUpdate.bind(this);
    AppEvents.AddEventListener(
      "auth-status-changed",
      this.$options.onAuthStatusUpdateH
    );

    this.$options.onNotificationsChangedH = this.onNotificationsChanged.bind(this);
    AppEvents.AddEventListener("notifications-status-changed", this.$options.onNotificationsChangedH);

    this.onAuthStatusUpdate();

    window.addEventListener("resize", this.handleResize);

  },
  beforeUnmount: function () {
    AppEvents.RemoveEventListener(
      "theme-changed",
      this.$options.onThemeChangedH
    );
    AppEvents.RemoveEventListener(
      "auth-status-changed",
      this.$options.onAuthStatusUpdateH
    );

    AppEvents.RemoveEventListener("notifications-status-changed", this.$options.onNotificationsChangedH);

    window.removeEventListener("resize", this.handleResize);
  },

});
