// Auth controller

import { AuthAPI, OrganizationMembershipContext } from "@/api/api-auth";
import { Request } from "@/utils/request";
import { Timeouts } from "@/utils/timeout";
import { AppEvents } from "./app-events";
import { LocalStorage } from "./local-storage";
import { AppPreferences } from "./app-preferences";
import { setCookie } from "@/utils/cookie";


export class AuthController {
    public static Status: "UNAUTHORIZED" | "LOGGED_IN" | "USER_NOT_FOUND" | "TFA_REQUIRED" = "UNAUTHORIZED";

    public static UID = "";
    public static AccountType = "";
    public static GlobalAdmin = false;
    public static ListPermissions = [];
    public static Username = "";
    public static Email = "";

    public static PageToGo = "home";

    public static RequiresTwoFactorAuthentication = false;

    public static Session = "";

    public static TokenSymbol = "";
    public static TokenName = "";
    public static TokenDecimals = 0;

    public static Organizations: OrganizationMembershipContext[] = [];

    public static SelectedOrganization = "";
    public static SelectedOrganizationMembership: OrganizationMembershipContext | null = null;

    public static Loading = true;

    public static Initialize() {
        AuthController.Session = LocalStorage.Get("x-session-token", "");
        AuthController.CheckAuthStatus();
        AppEvents.AddEventListener("unauthorized", AuthController.ClearSession);
    }

    public static isAuthenticated(): boolean {
        return AuthController.Status === "LOGGED_IN";
    }

    public static isAskingForTwoFactor(): boolean {
        return AuthController.Status === "TFA_REQUIRED";
    }

    public static CheckAuthStatus() {
        AuthController.Loading = true;
        AppEvents.Emit("auth-status-loading", true);
        Timeouts.Abort("auth-control-check");
        Request.Pending("auth-control-check", AuthAPI.Context()).onSuccess(response => {
            AuthController.Status = response.status;
            AuthController.UID = response.uid;
            AuthController.AccountType = response.account_type;
            AuthController.GlobalAdmin = response.global_admin;
            AuthController.ListPermissions = response.permissions;
            AuthController.Username = response.username;
            AuthController.Email = response.email;
            AuthController.RequiresTwoFactorAuthentication = response.tfa;
            AuthController.Loading = false;
            AuthController.TokenName = response.token_name;
            AuthController.TokenSymbol = response.token_symbol;
            AuthController.TokenDecimals = response.token_decimals;
            AuthController.Organizations = response.organizations || [];

            setCookie("session_id", AuthController.Session);

            const selectedOrg = AppPreferences.GetSelectedOrganization(AuthController.UID);

            AuthController.SelectedOrganization = "";
            AuthController.SelectedOrganizationMembership = null;

            if (selectedOrg) {
                for (const org of AuthController.Organizations) {
                    if (org.id === selectedOrg) {
                        AuthController.SelectedOrganization = org.id;
                        AuthController.SelectedOrganizationMembership = org;
                        break;
                    }
                }
            }

            AppEvents.Emit("auth-status-changed");
            AppEvents.Emit("auth-status-loading", false);
        }).onRequestError(err => {
            Request.ErrorHandler()
                .add(401, "TFA_REQUIRED", () => {
                    AuthController.Status = "TFA_REQUIRED";
                    AuthController.UID = "";
                    AuthController.Username = "";
                    AuthController.Loading = false;
                    AppEvents.Emit("auth-status-changed");
                    AppEvents.Emit("auth-status-loading", false);
                })
                .add(401, "*", () => {
                    AuthController.Status = "UNAUTHORIZED";
                    AuthController.UID = "";
                    AuthController.Username = "";
                    AuthController.Loading = false;
                    AppEvents.Emit("auth-status-changed");
                    AppEvents.Emit("auth-status-loading", false);
                })
                .add("*", "*", () => {
                    // Retry
                    Timeouts.Set("auth-control-check", 1500, AuthController.CheckAuthStatus);
                })
                .handle(err);
        }).onUnexpectedError(err => {
            console.error(err);
            // We assume the credentials are invalid
            AuthController.Status = "UNAUTHORIZED";
            AuthController.UID = "";
            AuthController.Username = "";
            AuthController.Loading = false;
            AppEvents.Emit("auth-status-changed");
            AppEvents.Emit("auth-status-loading", false);
        });
    }

    public static ClearSession() {
        AuthController.Status = "UNAUTHORIZED";
        AuthController.Session = "";

        LocalStorage.Set("x-session-token", "");

        AuthController.UID = "";
        AuthController.Username = "";

        AppEvents.Emit("auth-status-changed");
    }

    public static SetSession(session: string) {
        AuthController.Status = "UNAUTHORIZED";
        AuthController.Session = session;
        AuthController.UID = "";
        AuthController.Username = "";
        LocalStorage.Set("x-session-token", session);
        AppEvents.Emit("auth-status-changed");
        AuthController.CheckAuthStatus();
    }

    public static Logout() {
        const currentSession = AuthController.Session;
        Request.Do(AuthAPI.Logout()).onSuccess(() => {
            if (AuthController.Session === currentSession) {
                AuthController.ClearSession();
            }
        }).onRequestError(() => {
            if (AuthController.Session === currentSession) {
                AuthController.ClearSession();
            }
        });
    }

    public static CanRegisterProjects(): boolean {
        return AuthController.GlobalAdmin || AuthController.ListPermissions.includes("projects");
    }

    public static CanRegisterCertificationSchemes(): boolean {
        return AuthController.GlobalAdmin || AuthController.ListPermissions.includes("cert_scheme");
    }

    public static CanRegisterCertificationBodies(): boolean {
        return AuthController.GlobalAdmin || AuthController.ListPermissions.includes("cert_body");
    }

    public static CanBuy(): boolean {
        return AuthController.GlobalAdmin || !AuthController.ListPermissions.includes("projects") && !AuthController.ListPermissions.includes("cert_scheme") && !AuthController.ListPermissions.includes("cert_body");
    }

    public static FindOrganization(id: string): OrganizationMembershipContext | null {
        if (!id) {
            return null;
        }

        for (const org of AuthController.Organizations) {
            if (org.id === id) {
                return org;
            }
        }

        return null;
    }

    public static SelectOrganization(organizationId: string) {
        AppPreferences.SetSelectedOrganization(AuthController.UID, organizationId);

        AuthController.SelectedOrganization = "";
        AuthController.SelectedOrganizationMembership = null;

        if (organizationId) {
            for (const org of AuthController.Organizations) {
                if (org.id === organizationId) {
                    AuthController.SelectedOrganization = org.id;
                    AuthController.SelectedOrganizationMembership = org;
                    break;
                }
            }
        }

        AppEvents.Emit("auth-status-changed");
    }
}
