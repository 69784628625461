// Stripe account API

import { GetAPIURL, RequestParams } from "@/utils/request";

export class StripeAccountAPI {
    public static CreateStripeAccount(): RequestParams<{ url: string }> {
        return {
            method: "POST",
            url: GetAPIURL("/stripe-account"),
        };
    }

    public static RetrieveStripeAccount(): RequestParams<{ account: any }> {
        return {
            method: "GET",
            url: GetAPIURL("/stripe-account/get"),
        };
    }

    public static UpdateStripeAccount(): RequestParams<{
        [x: string]: string | URL; transferCode: string
    }> {
        return {
            method: "POST",
            url: GetAPIURL("/stripe-account/update"),
        };
    }
}